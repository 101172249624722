import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import {ActivatedRoute, Router} from "@angular/router";
import {PhotosProvider} from "../browser.lib.evaluation-tool/module.evaluation-services/service.photos/photos";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FirebaseSaver2} from "../browser.lib.evaluation-tool/util/FirebaseSaver2";
import {EvaluationSection} from "../javascript.lib.mojo-base/model/module/EvaluationSection";
import {AspectAnswers} from "../javascript.lib.mojo-base/model/AspectAnswers";
import {SectionAnswers1} from "../javascript.lib.mojo-base/model/module/SectionAnswers1";
import {QuestionListComponent} from "../browser.lib.evaluation-tool/module.evaluation-components/component.question-list/question-list.component";
import {PageQuestions} from "../javascript.lib.mojo-base/model/module/PageQuestions";
import {QuestionReference} from "../javascript.lib.mojo-base/model/Question";
import { ChangeDetectorRef } from '@angular/core';
import {BaseApplicationContext} from "../browser.lib.evaluation-tool/services.application-context/BaseApplicationContext";
import {EProductType} from "../javascript.lib.mojo-base/product.common/ProductType";
import {environment} from "../environments/environment";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-aspect-section',
  templateUrl: './aspect-section.component.html'
})
export class AspectSectionComponent implements OnInit {

  // private _log = LoggerFactory.build( 'AspectSectionComponent' );


  icons = {
    faBars
  };

  private aspectIndex = 0;
  sectionIndex: number = 0;

  private firebaseSaver: FirebaseSaver2;

  name: string;
  isSaving: boolean = false;
  private _aspectQuestions: EvaluationSection;
  aspectAnswers: AspectAnswers;

  sectionAnswers: SectionAnswers1 = null;


  @Input() propertyKey: string = null;
  @ViewChild(QuestionListComponent) questions: QuestionListComponent;

  sectionTitle: string = 'hey hey'

  getSectionAnswers( sectionIndex: number ): SectionAnswers1 {

    const sectionQuestions: PageQuestions = this.aspectAnswers.aspectQuestions.evaluationSections[sectionIndex];
    return new SectionAnswers1( sectionQuestions, this.aspectAnswers );
  }

  showIssuesToast() {
    this._snackBar.open( "Check for issues", null, {
      duration: 2000,
    });
  }


  async saveAspectAndPhotos( goingToHomeAfter: boolean) {

    this.isSaving = true;
    try {

      if( goingToHomeAfter ) {

        for( const question of this._aspectQuestions.questions ) {

          // force some sort of an answer to be created for all questions
          this.aspectAnswers.getAnswer( question );
        }
      }

      this.aspectAnswers.scrubMarkupTagsFromTextAnswers(); // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)

      await this.firebaseSaver.saveAspectAndPhotos(
        this.sessionContext.propertyContext.propertyKey,
        this.aspectAnswers );

    } finally {
      this.isSaving = false;
    }

  }

  private shouldSkipIndex( sectionIndex ): boolean {

    const sectionQuestions = this.aspectAnswers.aspectQuestions.evaluationSections[sectionIndex];

    // // skip the photos for the banking product
    // if( this.productIsBanking ) {
    //
    //   if( 1 === sectionQuestions.questions.length ) {
    //
    //     if( sectionQuestions.questions[0].isPhoto ) {
    //
    //       return true;
    //     }
    //   }
    // }


    const dependant = sectionQuestions.value.dependant;
    if( !dependant ) {

      return false;
    }

    const dependantQuestion: QuestionReference = this.aspectAnswers.aspectQuestions.questionsByKey[dependant.questionKey];
    if( !dependantQuestion ) {

      console.error( '!dependantQuestion', sectionIndex, dependant );
      return false;
    }

    const dependantAnswer = this.aspectAnswers.getAnswer( dependantQuestion );
    if( !dependantAnswer ) {

      console.error( '!dependantAnswer', dependantQuestion );
      return false;
    }

    if( dependantAnswer.isTruthy(dependant)) {

      return false;
    }

    console.log( 'dependantAnswer', dependantAnswer );
    return true;
  }



  async onPrevious() {

    this.questions.hasIssues(); // highlight the issues at least before moving on

    await this.saveAspectAndPhotos( false );

    if( 0 === this.sectionIndex ) {

      // this.navCtrl.pop();
      AppRouteManifest.HOME.navigate( this.router );
      return;

    } else {

      let nextSectionIndex = this.sectionIndex-1;

      while( this.shouldSkipIndex(nextSectionIndex)) {

        nextSectionIndex--;
      }

      AppRouteManifest.ASPECT_SECTION.navigateToAspect( this.router, this.aspectIndex, nextSectionIndex );
    }

  }

  async onNext() {

    // if( this.questions.hasIssues() ) {
    //
    //   this.showIssuesToast();
    //   return;
    // }
    this.questions.hasIssues(); // highlight the issues at least before moving on


    await this.saveAspectAndPhotos(false );

    const evaluationSectionCount = this.aspectAnswers.aspectQuestions.evaluationSections.length;

    if( evaluationSectionCount === (this.sectionIndex+1)) {

      // this.navCtrl.pop();
      await this.saveAspectAndPhotos( true );
      AppRouteManifest.HOME.navigate( this.router );

    } else {

      let nextSectionIndex = this.sectionIndex+1;

      // if( this.shouldSkipIndex(nextSectionIndex) ) {
      //
      //   nextSectionIndex++;
      //   while( nextSectionIndex < evaluationSectionCount && this.shouldSkipIndex(nextSectionIndex)) {
      //
      //     nextSectionIndex++;
      //   }
      // }
      while( nextSectionIndex < evaluationSectionCount && this.shouldSkipIndex(nextSectionIndex)) {

        nextSectionIndex++;
      }
      if( nextSectionIndex < evaluationSectionCount ) {

        AppRouteManifest.ASPECT_SECTION.navigateToAspect( this.router, this.aspectIndex, nextSectionIndex);

      } else {

        await this.saveAspectAndPhotos( true );
        AppRouteManifest.HOME.navigate( this.router );
      }

    }

  }

  sessionIsReady() {

    this.init();
  }



  async init() {

    this._aspectQuestions = this.sessionContext.propertyContext.product.evaluationSections[this.aspectIndex];

    {
      const sectionName = this._aspectQuestions.value.name;
      this.sectionTitle = `Section ${this.aspectIndex+1}: ${sectionName}`;
    }
    //
    this.aspectAnswers =  this.sessionContext.propertyContext.aspectAnswers[this.aspectIndex];
    this.sectionAnswers = this.getSectionAnswers( this.sectionIndex );

    this.changeDetector.markForCheck();

  }

  ngOnInit() {

    this.route.paramMap.subscribe( async (params) => {

      this.aspectIndex = await AppRouteManifest.ASPECT_SECTION.getAspectIndex( this.route );
      this.sectionIndex = await AppRouteManifest.ASPECT_SECTION.getSectionIndex( this.route);

      if( this.sessionContext.sessionIsReady) {

        await this.init();
      }
    });


  }

  constructor( public router: Router,
               private route: ActivatedRoute,
               public applicationContext: BaseApplicationContext,
               public sessionContext: PwaSessionContextProvider,
               public photos: PhotosProvider,
               public afDb: AngularFireDatabase,
               private _snackBar: MatSnackBar,
               public changeDetector:ChangeDetectorRef,
               public dialog: MatDialog
  ) {

    this.firebaseSaver = new FirebaseSaver2( afDb, photos, dialog );

  }


}
