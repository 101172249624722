import {BaseReference} from "../javascript.lib.mojo-base/model/BaseReference";


export interface IReportFeature {

  identifier: string;

  nocoDbReportFeatureId?: number;


  inclusionCriteria?: {

    ifLessThanFullScore: {
      questionKeys: string[];
    }
  }


  // version 2
  feature?: string;
  description?: string;
  images?: string[];

  AdaStandard?: string;
  BsStandard?: string;
  ISOStandard?: string;
  EnStandard?: string;


}

export class ReportFeature extends BaseReference<IReportFeature>{


  protected onSetValue(value: IReportFeature | null) {
  }



  constructor( value: IReportFeature ) {

    super(value);
  }

}


