

<div *ngIf="!sectionAnswers">
  Loading ...
</div>


<div *ngIf="sectionAnswers">

  <div *ngFor="let answer of sectionAnswers.answers">

    <div [ngSwitch]="answer.question.value.type">

      <div *ngIf="isTest" style="user-select: text">
        <code>
          {{answer.question.value.key}} ({{answer.question.value.nocoDbId}})
        </code>
      </div>

      <question-boolean *ngSwitchCase="questionTypes.TYPE_BOOLEAN"
                        [answer]="answer"
      >
      </question-boolean>

      <question-centimeters *ngSwitchCase="questionTypes.TYPE_CM_MEASUREMENT"
                            [questionAnswer]="answer"
      >
      </question-centimeters>

      <question-enum *ngSwitchCase="questionTypes.TYPE_ENUM"
                     [answer]="answer"
      >
      </question-enum>


      <question-integer *ngSwitchCase="questionTypes.TYPE_INTEGER"
                        [answer]="answer">
      </question-integer>


      <div *ngSwitchCase="questionTypes.TYPE_LINE">
        TYPE_LINE
      </div>


      <question-photo *ngSwitchCase="questionTypes.TYPE_PHOTO"
                      [answer]="answer"
                      [propertyKey]="propertyKey"
      >
      </question-photo>

      <question-ternary *ngSwitchCase="questionTypes.TYPE_TERNARY"
                        [answer]="answer">
      </question-ternary>

      <question-text *ngSwitchCase="questionTypes.TYPE_TEXT"
                     [questionAnswer]="answer">
      </question-text>

    </div>
  </div>

</div>
