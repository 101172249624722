import {AspectAnswers} from "../javascript.lib.mojo-base/model/AspectAnswers";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {PropertyReference} from "../javascript.lib.mojo-base/product.facilities/Property";
import {IScore} from "../javascript.lib.mojo-base/hotel/model/accessibility/Score";
import {FirebaseAnswers} from "../browser.lib.evaluation-tool/firebase/realtime-database/answers-x/FirebaseAnswers";
import {environment} from "../environments/environment";
import {IProduct} from "../model.product/IProduct";
import {ProductHelper} from "../model.product/ProductHelper";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {
  FirebaseConnectionService
} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirebaseEvaluationState} from "../javascript.lib.mojo-base/firebase/evaluation/FirebaseEvaluationState";
import {EvaluationStatus} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";


export class PwaPropertyContext {


  private static log = LoggerFactory.build( 'PwaPropertyContext' );


  public propertyKey: string;
  public aspectAnswers: AspectAnswers[] = [];

  public previouslyCompleted: boolean|null = null;

  public completedPageHasBeenShown: boolean = false;
  public showCongratulationsText: boolean = true;

  private _completed: boolean|null = null;
  private _score: IScore = null;

  public evaluationStatus: EvaluationStatus = new EvaluationStatus( null );


  willEditAnswers(): void {

    if( !this._completed ) {

      this._completed = null;
    }
    this._score = null;
  }

  isEvaluationCompleted(): boolean|null {

    if( null !== this._completed ) {

      return this._completed;
    }

    for( const aspect of this.aspectAnswers ) {

      if( !aspect.isCompleted() ) {
        this._completed = false;
        return this._completed;
      }
    }

    this._completed = true;
    return this._completed;
  }

  getScore(): IScore|null {

    if( this._score ) {

      return this._score;
    }

    if( !this.isEvaluationCompleted() ) {

      return null;
    }

    const score: IScore = {
      numerator: 0,
      denominator: 0
    }

    for( const aspect of this.aspectAnswers ) {

      const aspectScore = aspect.getScore();

      if( !aspectScore ) {

        continue;
        // return null;
      }

      score.numerator += aspectScore.numerator;
      score.denominator += aspectScore.denominator;
    }

    this._score = score;
    return this._score;
  }

  private static _getProduct( property: PropertyReference ): IProduct {

    if( property.value.excludePhotos ) {

      PwaPropertyContext.log.debug( 'property.value.excludePhotos' );
      return ProductHelper.cloneProduct( environment.product, true );
    }

    return environment.product;
  }

  public static async build( applicationContext: PwaApplicationContextProvider,
                             property: PropertyReference,
                             afDb: AngularFireDatabase,
                             firebaseConnection: FirebaseConnectionService ): Promise<PwaPropertyContext> {

    const product = PwaPropertyContext._getProduct( property );
    const answer = new PwaPropertyContext( property, product );

    answer.previouslyCompleted = true;

    for( const aspectQuestions of product.evaluationSections ) {

      const value = await FirebaseAnswers.readValueXByKey( afDb, property.propertyKey, aspectQuestions );

      const aspectAnswers: AspectAnswers = new AspectAnswers( value, aspectQuestions );
      answer.aspectAnswers.push( aspectAnswers );

      if (!aspectAnswers.isCompleted() ) {

        answer.previouslyCompleted = false;
      }

      let evaluationStatus = await FirebaseEvaluationState.read( firebaseConnection, environment.productConfig.productType, property.propertyKey );

      if( evaluationStatus ) {

        answer.evaluationStatus = evaluationStatus;
      } else {

        answer.evaluationStatus = new EvaluationStatus( {} );
      }
    }




    return answer;
  }

  private constructor( public property: PropertyReference,
                       public product: IProduct ) {

    this.propertyKey = property.propertyKey;
  }
}
