import {BaseReference} from "../../model/BaseReference";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {EProductType} from "../../product.common/ProductType";
import {IListingReponse} from "../ListingReponse";


export interface INocoProduct {

  Id: number;
  Title: string;
}

export class NocoProduct extends BaseReference<INocoProduct> {

  protected onSetValue(value: INocoProduct | null) {
  }

  constructor( value: INocoProduct | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }

}

export class NocoProductSet {

  values: NocoProduct[] = [];
  valuesById: {[id: number]: NocoProduct} = {};

  banking: NocoProduct;
  facilities: NocoProduct;
  manufacturing: NocoProduct;

  getProduct( productType: EProductType ): NocoProduct {

    if( productType === EProductType.banking) {

      return this.banking;
    }

    if( productType === EProductType.facilities) {

      return this.facilities;
    }

    if( productType === EProductType.manufacturing) {

      return this.manufacturing;
    }

    return null;

  }



  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoProduct>> {

    return proxy.getView<INocoProduct>( 'Product' );
  }


  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoProductSet> {

    const value: IListingReponse<INocoProduct> = await proxy.getView<INocoProduct>( 'Product' );
    return new NocoProductSet( value );
  }

  public constructor( public value: IListingReponse<INocoProduct> ) {

    for( const rowValue of value.list ) {

      const reference = new NocoProduct( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;

      if( 'Banking' === reference.value.Title) {

        this.banking = reference;

      } else if( 'Facilities' === reference.value.Title) {

        this.facilities = reference;

      } else if( 'Manufacturing' === reference.value.Title) {

        this.manufacturing = reference;
      }

    }


  }


}

