import {EvaluationSection, IAspectQuestions} from "../../javascript.lib.mojo-base/model/module/EvaluationSection";
import {FacilityEvaluationSections3} from "./FacilityEvaluationSections3";
import {SectionIdentifiers} from "./SectionIdentifiers";
import {SectionQuestions2} from "../../javascript.lib.mojo-base/model/module/SectionQuestions2";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";
import {QuestionSet} from "../../javascript.lib.mojo-base/model/QuestionSet";
import {FacilityQuestions2} from "./FacilityQuestions2";
import {FacilityQuestions4} from "./FacilityQuestions4";


export class FacilityEvaluationSections4 {

  private static _aspects: EvaluationSection[] = null;

  public static readonly PARKING_AND_ENTRANCE: SectionQuestions2[] = [

    //
    // SCREEN: #1
    //
    new SectionQuestions2( {
      title: "Parking",
      questionKeys: [

        'Bkp11', // Is there parking available for visitors/employees?
        'MpjwN', // Are parking charges in place?
        'Mpj2n', // Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?
        'BkqAn', // Are there designated wheelchair accessible parking spaces available?
        'BkqAx', // How many wheelchair accessible parking spaces are provided?
      ],
      subSections: []
    }),
    //
    // SCREEN: #2
    //
    new SectionQuestions2( {
      title: "Parking",
      questionKeys: [
        'ajdl0', // Is there a step-free and unobstructed route from the designated accessible parking space(s) to the most accessible building entrance?
        'BkqA7', // Is there an accessible entrance from the indoor car park to the main building?
        'BkqBF', // From the car park, does the building entrance have an intercom or two-way communication system?
        'BkqBP', // From the car park, is the entrance to the building through two doors in a series?
        'BkqBZ', // In centimetres, what is the distance between the two doors in the series? (ie. the clear space between the two doors).
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkp11', // Is there parking available for visitors/employees?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.street.codeAsNumber,
        ]
      }
    }),
    //
    // SCREEN: #3
    //
    new SectionQuestions2( {
      title: "Parking",
      questionKeys: [
        'BkqBj', // Please take 2 photos of the route from the accessible car parking spaces to the entrance of the building - (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkqAn', // Are there designated wheelchair accessible parking spaces available?
      }

    }),

    //
    // SCREEN: #4
    //
    new SectionQuestions2( {
      title: "Outdoor Spaces",
      questionKeys: [
        'goXFK', // Is your building part of a multi-building campus?
        'goXFo', // Are direction information signs or large orientation maps provided to help users navigate routes and locate key buildings?
        'gou4m', // Are lights provided along exterior circulation routes for continuous illumination and wayfinding at night?

      ],
      subSections: [],
    }),

    //
    // SCREEN: #5
    //
    new SectionQuestions2( {
      title: "Outdoor Spaces",
      questionKeys: [
        'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
        'goXGa', // Are there handrails on steps?
        'goXHC', // Do handrails colour contrast visually with the background to which they are fixed?
        'goXHg', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
      ],
      subSections: [],
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      }
    }),

    //
    // SCREEN: #6
    //
    new SectionQuestions2( {
      title: "Outdoor Spaces",
      questionKeys: [
        'goXII', // Are ramps provided in the main access routes to this building?
        'g5GgQ', // Are handrails provided on ramps?
        'g5G4A', // Do handrails colour contrast visually with the background to which they are fixed?
        'g7pf-', // Do ramps have a gentle angle/gradual slope?
      ],
      subSections: [],
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      }
    }),

    //
    // SCREEN: #7
    //
    new SectionQuestions2( {
      title: "Outdoor Spaces",
      questionKeys: [
        'goXIw', // Please take a photo of a ramp provided in the main access routes to the building.
      ],
      subSections: [],
      dependant: {
        questionKey: 'goXII', // Are ramps provided in the main access routes to this building?
      }
    }),

    //
    // SCREEN: #8
    //
    new SectionQuestions2( {
      title: "Outdoor Spaces",
      questionKeys: [
        'gou5E', // Are outdoor quiet spaces/resting areas provided for employees to use during breaks?
        'gou1o', // Are the ground surfaces of outdoor pedestrian pathways stable, firm, and slip-resistant?
        'gou2Q', // Do all pedestrian crossing points have dropped curbs?
        'gou30', // Are pedestrian crossing points marked with contrasting colour or tactile floor warning indicators?
      ],
      subSections: [],
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      }
    }),

    //
    // SCREEN: #9
    //
    new SectionQuestions2( {
      title: "Outdoor Spaces",
      questionKeys: [
        'gou24', // Please take a photo of a dropped curb in the main access routes to the building.
      ],
      subSections: [],
      dependant: {
        questionKey: 'gou2Q', // Do all pedestrian crossing points have dropped curbs?
      }
    }),

    //
    // SCREEN: #10
    //
    new SectionQuestions2( {
      title: "Building Entrances",
      questionKeys: [
        'BkqB3', // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
        'BkqCB', // How many steps are there up to the main entrance?
        'ajd8e', // Are handrails provided at steps?
      ],
      subSections: []
    }),
    //
    // SCREEN: #11
    //
    new SectionQuestions2( {
      title: "Building Entrances",
      questionKeys: [
        'BkqCL', // What type of door is there into the main entrance?
        'ajeoO', // How do the automatic doors open?
        'ajhxA', // Do automatic doors that swing open have audible warnings?
        'aji50', // What type of door handles are on the entrance door?
        'ajkEg', // Can the entrance door be opened by someone with limited strength?
      ],
      subSections: []
    }),
    //
    // SCREEN: #12
    //
    new SectionQuestions2( {
      title: "Building Entrances",
      questionKeys: [
        'BkqCf', // What is the main entrance door width measurement? (In cm)
        'BkqCp', // Is the main entrance door made of full clear glass panels?
        'BkqCz', // Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?
      ],
      subSections: []
    }),
    //
    // SCREEN: #13
    //
    new SectionQuestions2( {
      title: "Building Entrances",
      questionKeys: [
        'BkqC9', // Please take 2 photos of the main entrance (in landscape mode)
      ],
      subSections: []
    }),
    //
    // SCREEN: #14
    //
    new SectionQuestions2( {
      title: "Building Entrances",
      questionKeys: [
        'BkqCV', // Is there an alternative accessible entrance?
        'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
        'CjEZ3', // What type of door is there into the alternative accessible entrance?
        'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
        'BkqBt', // Is the alternative accessible entrance clearly signposted?
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkqCL', // What type of door is there into the main entrance?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber
        ]
      }
    }),
    //
    // SCREEN: #15
    //
    new SectionQuestions2( {
      title: "Building Entrances",
      questionKeys: [
        'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkqCV', // Is there an alternative accessible entrance?
      }
    }),
    //
    // SCREEN: #16
    //
    new SectionQuestions2( {
      title: "Reception Area",
      questionKeys: [
        'Bkve7', // Is there a lower counter/desk in the reception area?
        'BkvfF', // Are assistive listening systems provided at the reception area? (eg. Audio Induction Loop)
        'C9KCH', // Is there visitor seating provided at the reception area?
      ],
      subSections: []
    }),
    //
    // SCREEN: #17
    //
    new SectionQuestions2( {
      title: "Reception Area",
      questionKeys: [
        'BkvfP', // Is there directional and informational signage in the reception area?
        'Mpj2x', // Are tactile maps available in the reception/lobby area?
        'Bkvfj', // Are guide/service dogs welcome on the premises?
      ],
      subSections: []
    }),
    //
    // SCREEN: #18
    //
    new SectionQuestions2( {
      title: "Reception Area",
      questionKeys: [
        'BkvfZ', // Please take 2 photos of the reception area  1. Showing reception desk and 2. Directional signage if applicable (in landscape mode)
      ],
      subSections: []
    }),
    //
    // SCREEN: #19
    //
    new SectionQuestions2( {
      title: "",
      questionKeys: [
        'QZ_dz', // Do you have any other comments or information about this area?
      ],
      subSections: []
    }),
  ];
  public static readonly FACILITIES_AND_EVENT_SPACES: SectionQuestions2[] = [

    //
    // SCREEN: #1
    //
    new SectionQuestions2( {
      title: "Stairs",
      questionKeys: [
        'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        'BkzKF', // Are there flights of stairs within the building?
        'BkzKP', // Do the stairs have handrails that colour contrast with the surrounding walls?
        'ajmM6', // Are internal stairs well lit?
        'BkzeF', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
      ],
      subSections: []
    }),
    //
    // SCREEN: #2
    //
    new SectionQuestions2( {
      title: "Lift/Elevators",
      questionKeys: [
        'Bkz__', // Is there a lift/elevator in the building?
        'Bk0AJ', // Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.
        'ajmsU', // Are the lift/elevator call buttons reachable from a seated position and free from obstructions?
        'ajm9M', // Do the lift/elevator call buttons have visual indicators?
      ],
      subSections: [],
      dependant: {
        questionKey: 'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        truthyBooleanValue: false
      }

    }),
    //
    // SCREEN: #3
    //
    new SectionQuestions2( {
      title: "Lift/Elevators",
      questionKeys: [
        'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
        'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
        'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
      }
    }),
    //
    // SCREEN: #4
    //
    new SectionQuestions2( {
      title: "Lift/Elevators",
      questionKeys: [
        'Bk0BF', // Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?
        'ajnKo', // Inside the lift/elevator, are there visual indicators showing the current floor position?
        'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
        'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
      }
    }),

    //
    // SCREEN: #5
    //
    new SectionQuestions2( {
      title: "Lift/Elevators",
      questionKeys: [
        'ajnX6',  // Does the emergency alarm in the lift/elevator have braille or raised letters?
        'XP_vG',  // Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?
        'TgYOI',  // Is there clear signage to indicate that lift/elevators can or cannot be used in an emergency situation?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
      }

    }),
    //
    // SCREEN: #6
    //
    new SectionQuestions2( {
      title: "Lift/Elevators",
      questionKeys: [
        'Bk0BP',  // Please take 1 photo of the most accessible lift/elevator with the doors open (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        truthyBooleanValue: false
      }
    }),
    //
    // SCREEN: #7
    //
    new SectionQuestions2( {
      title: "Hallways & Corridors",
      questionKeys: [
        'Bky5D',  // Are all main routes clearly defined to assist the orientation of people with visual impairments? For example, by using colour contrast between wall and floor surfaces.
        'Bky5N',  // Is there suitable and sufficient lighting providing good illumination throughout the building?
      ],
      subSections: []
    }),
    //
    // SCREEN: #8
    //
    new SectionQuestions2( {
      title: "Hallways & Corridors",
      questionKeys: [
        'Bky5X', // Please take 1 photo of a typical main route/corridor (in landscape mode)
      ],
      subSections: []
    }),


    //
    // SCREEN: #9
    //
    new SectionQuestions2( {
      title: "General Facilities",
      questionKeys: [
        'goVTa', // Is pre-visit information provided for employees and visitors?
        'goXCC', // Is a simple plan of the interior layout of the building available for employees?
        'goXEi', // Are plants and biophilic design principles widely incorporated throughout the indoor areas of your building?
      ],
      subSections: []
    }),


    //
    // SCREEN: #10
    //
    new SectionQuestions2( {
      title: "General facilities",
      questionKeys: [
        'BkyWD', // Are there cloakrooms available on the premises?
        'BkyWN', // Are some coat hooks reacheable from a seated position?
        'BkyWX', // Are there storage lockers available on the premises?
        'BkyWh', // Are there storage lockers available at different heights?
      ],
      subSections: []
    }),
    //
    // SCREEN: #11
    //
    new SectionQuestions2( {
      title: "General facilities",
      questionKeys: [
        'BkyVv', // Are baby changing facilities available on the premises?
        'BkyV5', // Is there a baby feeding room on the premises?
        'Mpj4z', // Are there designated quiet spaces or rooms in the building?
      ],
      subSections: []
    }),
    //
    // SCREEN: #12
    //
    new SectionQuestions2( {
      title: "General facilities",
      questionKeys: [
        'BkyWr', // Are there gym facilities on the premises?
        'BkyW1', // Is there step-free access into the gym? This could be level, ramp, or lift access.
        'Mpj4L', // Are there vending machines in the building?
        'Mpj4V', // Is the control panel on the vending machine operable from a seated position?
      ],
      subSections: []
    }),

    //
    // SCREEN: #13
    //
    new SectionQuestions2( {
      title: "Cafeteria & Food Services",
      questionKeys: [
        'BkzJT', // Is there a cafeteria on the premises?
        'BkzJd', // Is there step-free access into the cafeteria? This could be level, ramp, or lift access.
        'Mpj27', // What type of cafeteria service is provided?
      ],
      subSections: []
    }),
    //
    // SCREEN: #14
    //
    new SectionQuestions2( {
      title: "Cafeteria & Food Services",
      questionKeys: [
        'Mpj3F', // What is the height of the food counters from the floor to the top of the counter?
        'BkzJn', // Are the tables and chairs movable or fixed to the floor?
        'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
        'BkzJx', // Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkzJT', // Is there a cafeteria on the premises?
      }
    }),
    //
    // SCREEN: #15
    //
    new SectionQuestions2( {
      title: "Cafeteria & Food Services",
      questionKeys: [
        'ajlli', // Are menus available in print?
        'ajluw', // Are the menus available in larger print?
        'ajl5E', // Are menus available in digital format?
        'ajmCw', // Are menus available in braille?
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkzJT', // Is there a cafeteria on the premises?
      }
    }),
    //
    // SCREEN: #16
    //
    new SectionQuestions2( {
      title: "Cafeteria & Food Services",
      questionKeys: [
        'goXCg', // Do on-site catering services offer pre-ordering systems?
        'g5E62', // Do on-site catering services offer pre-payment systems?
        'Mpj33', // Are there food display cabinets or shelves where food and drink products are on display for purchase?
        'Mpj4B', // Are the food and drink products displayed vertically on shelves and in cabinets?
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkzJT', // Is there a cafeteria on the premises?
      }
    }),
    //
    // SCREEN: #17
    //
    new SectionQuestions2( {
      title: "Cafeteria & Food Services",
      questionKeys: [
        'BkzJw', // Please take 3 photos of the cafeteria from different angles (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'BkzJT', // Is there a cafeteria on the premises?
      }
    }),
    //
    // SCREEN: #18
    //
    new SectionQuestions2( {
      title: "Breakout Areas",
      questionKeys: [
        'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
        'Cmgc1', // Is there step-free access into some of the breakout areas? This could be level, ramp, or lift access.
        'CmgpB', // Can all appliances be reached from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)
        'CmgyZ', // Can the sink be reached from a seated position in the most accessible breakout area?
        'Cmie_', // Do kitchen appliances colour contrast with countertops in the most accessible breakout area?
      ],
      subSections: []
    }),
    //
    // SCREEN: #19
    //
    new SectionQuestions2( {
      title: "Breakout Areas",
      questionKeys: [
        'Cmg8t', // Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?
        'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
      ],
      subSections: [],
      dependant: {
        questionKey: 'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
      }
    }),
    //
    // SCREEN: #20
    //
    new SectionQuestions2( {
      title: "Breakout Areas",
      questionKeys: [
        'BkzJ7', // Please take 3 photos of the most accessible breakout area from different angles (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
      }
    }),
    //
    // SCREEN: #21
    //
    new SectionQuestions2( {
      title: "Bar/Lounge Area",
      questionKeys: [
        'QZ_lT', // Is there a bar or lounge that serves alcoholic beverages in the office?
        'QZ_ob', // Is there step-free access into the bar/lounge? This could be level, ramp, or lift access.
      ],
      subSections: []
    }),
    //
    // SCREEN: #22
    //
    new SectionQuestions2( {
      title: "Bar/Lounge Area",
      questionKeys: [
        'QZ_pD', // Is any part of the bar counter set at a lower height?
        'QZ_p_', // In the bar/lounge area, are the tables and chairs movable or fixed to the floor?
        'QZ_qx', // Are some lower tables provided where wheelchair users or persons of short stature can rest their plates and drinks?
        'QZ_rZ', // Is there at least 91cm of clear aisle space around some tables and chairs?
      ],
      subSections: [],
      dependant: {
        questionKey: 'QZ_lT', // Is there a bar or lounge that serves alcoholic beverages in the office?
      }
    }),
    //
    // SCREEN: #23
    //
    new SectionQuestions2( {
      title: "Bar/Lounge Area",
      questionKeys: [
        'QZ_sf', // Please take 2 photo of the most accessible bar or lounge area (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'QZ_lT', // Is there a bar or lounge that serves alcoholic beverages in the office?
      }
    }),
    //
    // SCREEN: #24
    //
    new SectionQuestions2( {
      title: "Event Spaces",
      questionKeys: [
        'Bkzpp', // Is there an auditorium in the building?
        'Bkzpz', // Is there a designated space for wheelchair users within the auditorium?
        'Mpj4f', // Is there a clear line of sight from the wheelchair designated space to the presenter in the auditorium?
      ],
      subSections: []
    }),
    //
    // SCREEN: #25
    //
    new SectionQuestions2( {
      title: "Event Spaces",
      questionKeys: [
        'Bkzp9', // Are assistive listening systems provided in the auditorium? (eg. Audio Induction Loop)
        'BkzqH', // Is there a text display or subtitles option on presentation screens provided in the auditorium?
        'g792U', // Is there sufficient lighting in the auditorium to support lip reading and sign language interpreting?
        'Mpj4p', // Is there space for a guide/service dog in the auditorium?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      }
    }),
    //
    // SCREEN: #26
    //
    new SectionQuestions2( {
      title: "Event Spaces",
      questionKeys: [
        'Bkzqb', // Please take 2 photos of the auditorium from different angles (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      }
    }),
    //
    // SCREEN: #27
    //
    new SectionQuestions2( {
      title: "Event Spaces",
      questionKeys: [
        'g79xU', // Is there an elevated stage/speaker area in the auditorium
        'g79yu', // Are there steps or ramps up to the stage/presenter area?
        'g79zq', // Are handrails provided at steps?
        'g7906', // Does the ramp have a gentle angle?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      }
    }),

    //
    // SCREEN: #28
    //
    new SectionQuestions2( {
      title: "Event Spaces",
      questionKeys: [
        'g793k', // Please take 2 photos of the ramp from different angles (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'g79yu', // Are there steps or ramps up to the stage/presenter area?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STAGE_ACCESS.both.codeAsNumber,
          FacilityEnumeratedConstants.STAGE_ACCESS.ramp.codeAsNumber,
        ]

      }

    }),

    //
    // SCREEN: #29
    //
    new SectionQuestions2( {
      title: "Event Spaces",
      questionKeys: [
        'g8QA-', // Is there a stand (lectern) or table available for presenters?
        'g8QVI', // Is the stand (lectern) or table accessible for wheelchair users and persons of shorter stature?
      ],
      subSections: []
    }),

    //
    // SCREEN: #30
    //
    new SectionQuestions2( {
      title: "Any other comments or feedback",
      questionKeys: [
        'QZ_t5', // Do you have any other comments or information about this area?
      ],
      subSections: []
    }),
  ];
  public static readonly WORK_STATIONS_AND_MEETING_ROOMS: SectionQuestions2[] = [
    //
    // SCREEN: #1
    //
    new SectionQuestions2( {
      title: "Work stations",
      questionKeys: [
        'Bk2BD', // Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?
        'Bk2BN', // Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.)
        'Bk2Br', // Are some office desks height adjustable?
        'Bk2BX', // Are some switches and sockets reachable from a seated position?
        'Bk2Bh', // Do some switches and sockets colour contrast to the background which they are mounted against?
      ],
      subSections: []
    }),
    //
    // SCREEN: #2
    //
    new SectionQuestions2( {
      title: "Work stations",
      questionKeys: [
        'Bk2B_', // Are some printers and photocopying machines operable from a seated position?
        'goVOk', // Can employees opt to work from a permanent desk space?
        'goXC-', // Are noise cancelling headphones available for employees?
        'goXDw', // Do employees have access to control panels that adjust lighting levels in the office workstations?
        'goXEO', // Do employees have access to control panels that adjust temperature in the office workstations?
      ],
      subSections: []
    }),
    //
    // SCREEN: #3
    //
    new SectionQuestions2( {
      title: "Work stations",
      questionKeys: [
        'Bk2B1', // Please take 2 photos of the most accessible office workstation area from different angles (in landscape mode)
      ],
      subSections: []
    }),
    //
    // SCREEN: #4
    //
    new SectionQuestions2( {
      title: "Meeting Rooms",
      questionKeys: [
        'Bk0fj', // Are there meeting rooms on the premises?
        'Bk0ft', // How many of the meeting rooms are wheelchair accessible?
        'Bk0f3', // Is there braille, raised lettering, or appropriate symbols on the meeting room signage?
      ],
      subSections: []
    }),
    //
    // SCREEN: #5
    //
    new SectionQuestions2( {
      title: "Meeting Rooms",
      questionKeys: [
        'Bk0gB', // What is the door width measurement of the most accessible meeting room? (In cm)
        'Bk0gL', // Can the door to the most accessible meeting room be opened by someone with limited strength?
        'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
        'eau2O', // Is there at least 91cm of clear aisle space around some tables and chairs?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bk0ft', // How many of the meeting rooms are wheelchair accessible?
      }
    }),
    //
    // SCREEN: #6
    //
    new SectionQuestions2( {
      title: "Meeting Rooms",
      questionKeys: [
        'Bk0gf', // Are assistive listening systems provided in any of the meeting rooms? (eg. Audio Induction Loop)
        'Cmlsx', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bk0fj', // Are there meeting rooms on the premises?
      }
    }),
    //
    // SCREEN: #7
    //
    new SectionQuestions2( {
      title: "Meeting Rooms",
      questionKeys: [
        'Bk0gp', // Please take 1 photo of the most accessible meeting room (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bk0fj', // Are there meeting rooms on the premises?
      }
    }),
    //
    // SCREEN: #8
    //
    new SectionQuestions2( {
      title: "Training Rooms",
      questionKeys: [
        'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
        'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
        'Bk1db', // Is there braille, raised lettering, or appropriate symbols on the training room signage?
      ],
      subSections: []
    }),
    //
    // SCREEN: #9
    //
    new SectionQuestions2( {
      title: "Training Rooms",
      questionKeys: [
        'Bk1dl', // What is the width of the most accessible training room door? (In cm)
        'Bk1dv', // Can the door to the most accessible training room be opened by someone with limited strength?
        'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
        'eau3K', // Is there at least 91cm of clear aisle space around some tables and chairs?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      }
    }),
    //
    // SCREEN: #10
    //
    new SectionQuestions2( {
      title: "Training Rooms",
      questionKeys: [
        'Bk1eD', // Are assistive listening systems provided in any of the training rooms? (eg. Audio Induction Loop)
        'Bk1eN', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      }
    }),
    //
    // SCREEN: #11
    //
    new SectionQuestions2( {
      title: "Training Rooms",
      questionKeys: [
        'Bk1eX', // Please take 1 photo of the most accessible training room (in landscape mode)
      ],
      subSections: [],
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      }
    }),
    //
    // SCREEN: #12
    //
    new SectionQuestions2( {
      title: "Any other comments or feedback",
      questionKeys: [
        'QZ_yl', // Do you have any other comments or information about this area?
      ],
      subSections: []
    }),
  ];



  public static getEvaluationSections(): EvaluationSection[] {

    if( null == FacilityEvaluationSections4._aspects ) {
      FacilityEvaluationSections4._aspects = FacilityEvaluationSections3.evaluationSections;

      const aspects: EvaluationSection[] = [];

      for( const original of FacilityEvaluationSections3.evaluationSections ) {

        const originalJson = JSON.stringify( original.value );
        const cloneValue: IAspectQuestions = JSON.parse( originalJson );


        if( cloneValue.firebaseAspectId === SectionIdentifiers.PARKING_AND_ENTRANCE_ID ) {

          cloneValue.sections.evaluation = SectionQuestions2.toSectionQuestions1(FacilityEvaluationSections4.PARKING_AND_ENTRANCE);
          cloneValue.questions = QuestionSet.joinValues( FacilityQuestions2.PARKING_AND_ENTRANCE_QUESTIONS,
            FacilityQuestions4.EXTRA_PARKING_AND_ENTRANCE_QUESTIONS.value );

        } else if( cloneValue.firebaseAspectId === SectionIdentifiers.FACILITIES_AND_EVENT_SPACES_ID ) {

          cloneValue.sections.evaluation = SectionQuestions2.toSectionQuestions1(FacilityEvaluationSections4.FACILITIES_AND_EVENT_SPACES);
          cloneValue.questions = QuestionSet.joinValues( FacilityQuestions2.FACILITIES_AND_EVENT_SPACES_QUESTIONS,
            FacilityQuestions4.EXTRA_FACILITIES_AND_EVENT_SPACES_QUESTIONS.value );
        } else if( cloneValue.firebaseAspectId === SectionIdentifiers.WORK_STATIONS_AND_ROOMS_ID ) {

          cloneValue.sections.evaluation = SectionQuestions2.toSectionQuestions1(FacilityEvaluationSections4.WORK_STATIONS_AND_MEETING_ROOMS);
          cloneValue.questions = QuestionSet.joinValues( FacilityQuestions2.WORK_STATIONS_AND_ROOMS_QUESTIONS,
            FacilityQuestions4.EXTRA_WORK_STATIONS_AND_MEETING_ROOMS_QUESTIONS.value );
        } else {

          // console.log( 'cloneValue.firebaseAspectId', cloneValue.firebaseAspectId );
        }


        aspects.push( new EvaluationSection( cloneValue ));
      }

      this._aspects = aspects;
    }

    return FacilityEvaluationSections4._aspects;
  }

}
