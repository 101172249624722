import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {BankingEnumeratedConstants} from "../../product.banking/model/BankingEnumeratedConstants";
import {NocoDbId} from "../../javascript.lib.mojo-base/nocodb/model/NocoDbId";


export interface IEnumeratedType {

  exampleQuestionId?: string;
  nocoDbId: NocoDbId;
  nocoDbDescription: string;
  typeName: string;
  values: any;
}

export interface IEnumeratedConstants {
  _enumeratedTypeId: number;
}

export class FacilityEnumeratedConstants  {


  public static YES = EnumeratedConstantReference.yes;
  public static NO = EnumeratedConstantReference.no;
  public static NOT_APPLICABLE = EnumeratedConstantReference.notApplicable;


  // Does the building have an accessibility certification, inclusion or universal design standards accreditation?  (row: 128)
  public static ACCESSIBILITY_CERTIFICATION: {

    ada: IEnumeratedConstant,
    dac: IEnumeratedConstant,
    partM: IEnumeratedConstant,
    bac: IEnumeratedConstant,
    nbc: IEnumeratedConstant,
    bbr: IEnumeratedConstant,
    other: IEnumeratedConstant,
    none: IEnumeratedConstant,
  } = {

    ada: {
      codeAsNumber: 0x616461,
      codeAsString: 'ada',
      label: 'ADA Cert (USA)',
      popupLabel: 'ADA Certificate',
      nocoId: 152,
    },
    dac: {
      codeAsNumber: 0x646163,
      codeAsString: 'dac',
      label: 'Disability Access Cert',
      popupLabel: 'Disability Access Certificate',
    },
    partM: {
      codeAsNumber: 0x7072746d,
      codeAsString: 'prtm',
      label: 'Part M (UK & Ireland)',
      popupLabel: 'Part M (UK & Ireland) Certificate',
      nocoId: 153,
    },
    bac: {
      codeAsNumber: 0x626163,
      codeAsString: 'bac',
      label: 'BAC (Australia)',
      popupLabel: 'BAC (Australia) Certificate',
      nocoId: 154,
    },
    nbc: {
      codeAsNumber: 0x6e6263,
      codeAsString: 'nbc',
      label: 'NBC (Canada)',
      popupLabel: 'NBC (Canada) Certificate',
    },
    bbr: {
      codeAsNumber: 0x626272,
      codeAsString: 'bbr',
      label: 'BBR (Sweden)',
      popupLabel: 'BBR (Sweden) Certificate',
      nocoId: 155,
    },
    other: {
      codeAsNumber: 0x6f746872,
      codeAsString: 'othr',
      label: 'Other',
      popupLabel: null,
      nocoId: 156
    },
    none: {
      codeAsNumber: 0x6e6f6e65,
      codeAsString: 'none',
      label: 'None',
      popupLabel: '',
      nocoId: 158,
    },

  };

  public static ALTERNATE_ENTRANCE_DOOR_TYPE: {

    automatic: IEnumeratedConstant,
    pushPad: IEnumeratedConstant,
    manual: IEnumeratedConstant,
    // revolving: IEnumeratedConstant,
  } = {

    automatic: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Fully Automatic',
      popupLabel: 'Fully Automatic Door At Alternative Accessible Entrance',
      nocoId: 94,
    },
    pushPad: {
      codeAsNumber: 0x70706164,
      codeAsString: 'ppad',
      label: 'Push Pad Activated',
      popupLabel: 'Push Pad Activated Door At Alternative Accessible Entrance',
      nocoId: 96,
    },
    manual: {
      codeAsNumber: 0x6d616e75,
      codeAsString: 'manu',
      label: 'Manual',
      popupLabel: 'Manual Door At Alternative Accessible Entrance',
      nocoId: 95,
    },
    // revolving: {
    //   codeAsNumber: 0x7265766f,
    //   codeAsString: 'revo',
    //   label: 'Revolving',
    //   popupLabel: 'Revolving Door At Alternative Accessible Entrance',
    // },
  };

  public static AUTOMATIC_DOOR_OPENING_TYPE: {
    inOut: IEnumeratedConstant,
    sideways: IEnumeratedConstant,
  } = {
    inOut: {
      codeAsNumber: 0x696e6f75,
      codeAsString: 'inou',
      label: 'Swing inward/outward',
      popupLabel: 'Automatic Doors Swing inward/outward',
    },
    sideways: {
      codeAsNumber: 0x73696465,
      codeAsString: 'side',
      label: 'Slide sideways',
      popupLabel: 'Automatic Doors slide sideways',
    },
  };

  public static BATHROOM_DOOR_LOCK_TYPE: {
    leverHandle: IEnumeratedConstant,
    slideBolt: IEnumeratedConstant,
    thumbTurn: IEnumeratedConstant,
    other: IEnumeratedConstant,
  } = {
    leverHandle: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever handle lock',
      popupLabel: 'Lever Handle Door Lock',
      nocoId: 124,
    },
    slideBolt: {
      codeAsNumber: 0x73626c74,
      codeAsString: 'sblt',
      label: 'Latch slide bolt lock',
      popupLabel: 'Latch Slide Bolt Door Lock',
      nocoId: 125,
    },
    thumbTurn: {
      codeAsNumber: 0x7474726e,
      codeAsString: 'ttrn',
      label: 'Thumb Turn lock',
      popupLabel: 'Thumb Turn Door Lock',
      nocoId: 126,
    },
    other: {
      codeAsNumber: 0x6f746872,
      codeAsString: 'othr',
      label: 'Other Door Lock',
      popupLabel: null,
      nocoId: 127,
    },
  };


  public static CAFETERIA_TYPE: {
    selfService: IEnumeratedConstant,
    buffetService: IEnumeratedConstant,
    counterService: IEnumeratedConstant,
    tableService: IEnumeratedConstant,
  } = {

    selfService: {
      codeAsNumber: 0x73656c66,
      codeAsString: 'self',
      label: 'Self-service',
      popupLabel: 'Self-service',
      nocoId: 97,
    },
    buffetService: {
      codeAsNumber: 0x62756666,
      codeAsString: 'buff',
      label: 'Buffet-service',
      popupLabel: 'Buffet-service',
      nocoId: 98,
    },
    counterService: {
      codeAsNumber: 0x636e7472,
      codeAsString: 'cntr',
      label: 'Counter service',
      popupLabel: 'Counter service',
      nocoId: 99,
    },
    tableService: {
      codeAsNumber: 0x7461626c,
      codeAsString: 'tabl',
      label: 'Table service',
      popupLabel: 'Table service',
      nocoId: 100,
    },
  };


  // Does the building have a car park? ... (row: 3)
  public static CAR_PARK_FACILITIES: {
    no: IEnumeratedConstant,
    outdoor: IEnumeratedConstant,
    indoor: IEnumeratedConstant,
    both: IEnumeratedConstant,
    street: IEnumeratedConstant,
  } = {

    no: {
      codeAsNumber: 0x6e6f,
      codeAsString: 'no',
      label: 'No',
      popupLabel: null,
      nocoId: 83,
    },
    outdoor: {
      codeAsNumber: 0x6f757464,
      codeAsString: 'outd',
      label: 'Outdoor Car Park',
      popupLabel: 'Outdoor Car Park',
      nocoId: 79,
    },
    indoor: {
      codeAsNumber: 0x696e64,
      codeAsString: 'ind',
      label: 'Indoor Car Park',
      popupLabel: 'Indoor Car Park',
      nocoId: 80,
    },
    both: {
      codeAsNumber: 0x626f7468,
      codeAsString: 'both',
      label: 'Both Indoor and Outdoor Car Park',
      popupLabel: 'Both Indoor and Outdoor Car Park',
      nocoId: 81,
    },
    street: {
      codeAsNumber: 0x73747274,
      codeAsString: 'strt',
      label: 'On-Street Parking',
      popupLabel: 'On-Street Parking',
      nocoId: 82,
    },
  };


  // public static CATERING_PREORDER_PREPAYMENT_SYSTEMS: {
  //   preOrder: IEnumeratedConstant,
  //   prePayment: IEnumeratedConstant,
  //   none: IEnumeratedConstant,
  //
  // } = {
  //   preOrder: {
  //     codeAsNumber: 0x0,
  //     codeAsString: 'ordr',
  //     label: 'Pre-ordered food system ',
  //     popupLabel: 'Pre-ordered food system',
  //   },
  //   prePayment: {
  //     codeAsNumber: 0x0,
  //     codeAsString: 'pay',
  //     label: 'Pre-payment system',
  //     popupLabel: 'Pre-payment system',
  //   },
  //   none: {
  //     codeAsNumber: 0x0,
  //     codeAsString: 'no',
  //     label: 'No',
  //     popupLabel: 'No',
  //   }
  // }


  // 'BkqCL', 'CjEZ3'
  public static DOOR_TYPE: {

    automatic: IEnumeratedConstant,
    pushPad: IEnumeratedConstant,
    manual: IEnumeratedConstant,
    revolving: IEnumeratedConstant,
  } = {

    automatic: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Automatic',
      popupLabel: 'Fully Automatic Door At Entrance',
      nocoId: 84,
    },
    pushPad: {
      codeAsNumber: 0x70706164,
      codeAsString: 'ppad',
      label: 'Push Pad Activated',
      popupLabel: 'Push Pad Activated Door At Entrance',
      nocoId: 85,
    },
    manual: {
      codeAsNumber: 0x6d616e75,
      codeAsString: 'manu',
      label: 'Manual',
      popupLabel: 'Manual Door At Entrance',
      nocoId: 86,
    },
    revolving: {
      codeAsNumber: 0x7265766f,
      codeAsString: 'revo',
      label: 'Revolving',
      popupLabel: 'Revolving Door At Entrance',
      nocoId: 87,
    },
  };

  public static ENTRANCE_DOOR_HANDLE: {

      lever: IEnumeratedConstant,
      vertical: IEnumeratedConstant,
      round: IEnumeratedConstant,
      push: IEnumeratedConstant,
    } = {
    lever: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever handle',
      popupLabel: 'Lever Door Handles',
      nocoId: 90,
    },
    vertical: {
      codeAsNumber: 0x76657274,
      codeAsString: 'vert',
      label: 'Vertical bar handle',
      popupLabel: 'Vertical Bar Door Handles',
      nocoId: 91,
    },
    round: {
      codeAsNumber: 0x726f756e,
      codeAsString: 'roun',
      label: 'Round handle',
      popupLabel: 'Round Door Handles',
      nocoId: 92,
    },
    push: {
      codeAsNumber: 0x70757368,
      codeAsString: 'push',
      label: 'Push door without handles',
      popupLabel: 'Push/No Door Handles',
      nocoId: 93,
    },
  };



  // 'BkzJn' / 'CmhaZ'
  public static FURNITURE_TYPE: {
    movable: IEnumeratedConstant,
    fixed: IEnumeratedConstant,
    both: IEnumeratedConstant,
    notApplicable: IEnumeratedConstant,
  } = {
    movable: {
      codeAsNumber: 0x6d6f7661,
      codeAsString: 'mova',
      label: 'Movable',
      popupLabel: 'Movable Tables And Chairs',
      nocoId: 108,
    },
    fixed: {
      codeAsNumber: 0x66697864,
      codeAsString: 'fixd',
      label: 'Fixed',
      popupLabel: 'Fixed Tables And Chairs',
      nocoId: 109,
    },
    both: {
      codeAsNumber: 0x626f7468,
      codeAsString: 'both',
      label: 'Movable and fixed',
      popupLabel: 'Both Movable And Fixed Tables And Chairs',
      nocoId: 110,
    },
    notApplicable: EnumeratedConstantReference.notApplicable
  };

  public static STAGE_ACCESS: {
    steps: IEnumeratedConstant,
    ramp: IEnumeratedConstant,
    both: IEnumeratedConstant,
    none: IEnumeratedConstant,
  } = {
    steps: {
      codeAsNumber: 0x73746570,
      codeAsString: 'step',
      label: 'Only steps',
      popupLabel: 'Stage accessible by steps',
      nocoId: 231,
    },
    ramp: {
      codeAsNumber: 0x72616d70,
      codeAsString: 'ramp',
      label: 'Only ramp',
      popupLabel: 'Stage accessible by ramp',
      nocoId: 232,
    },
    both: {
      codeAsNumber: 0x626f7468,
      codeAsString: 'both',
      label: 'Both steps and a ramp',
      popupLabel: 'Stage accessible by steps or ramp',
      nocoId: 233,
    },
    none: {
      codeAsNumber: 0x6e6f6e65,
      codeAsString: 'none',
      label: 'No',
      popupLabel: null,
      nocoId: 234,
    }
  }


  public static STEP_FREE_ACCESS_TYPE: {
    level: IEnumeratedConstant,
    ramp: IEnumeratedConstant,
    lift: IEnumeratedConstant,
  } = {
    level: {
      codeAsNumber: 0x6c65766c,
      codeAsString: 'levl',
      label: 'Level',
      popupLabel: 'Level entrance',
    },
    ramp: {
      codeAsNumber: 0x72616d70,
      codeAsString: 'ramp',
      label: 'Ramp',
      popupLabel: 'Ramp entrance',
    },
    lift: {
      codeAsNumber: 0x6c696674,
      codeAsString: 'lift',
      label: 'Lift',
      popupLabel: 'Lift entrance',
    },
  }


  public static STEP_FREE_BUILDING_ACCESS_ROUTES: {
    all: IEnumeratedConstant,
    some: IEnumeratedConstant,
    none: IEnumeratedConstant,

  } = {
    all: {
      codeAsNumber: 0x616c6c,
      codeAsString: 'all',
      label: 'All routes are step-free',
      popupLabel: 'All routes are step-free',
      nocoId: 235,
    },
    some: {
      codeAsNumber: 0x736f6d65,
      codeAsString: 'some',
      label: 'Some routes are step-free',
      popupLabel: 'Some routes are step-free',
      nocoId: 236,
    },
    none: {
      codeAsNumber: 0x6e6f6e65,
      codeAsString: 'none',
      label: 'No routes are step-free',
      popupLabel: 'No routes are step-free',
      nocoId: 237,
    }
  }


  public static TOILET_DOOR_TYPE: {

    automaticDoor: IEnumeratedConstant,
    pushButtonOpener: IEnumeratedConstant,
    leverHandles: IEnumeratedConstant,
    roundHandles: IEnumeratedConstant,
    push: IEnumeratedConstant,
  } = {

    automaticDoor: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Automatic door',
      popupLabel: 'Automatic Door Handles',
      nocoId: 133,
    },
    pushButtonOpener: {
      codeAsNumber: 0x6275746e,
      codeAsString: 'butn',
      label: 'Push Button/Pad opener',
      popupLabel: 'Push Button/Pad Door Handles',
      nocoId: 134,
    },
    leverHandles: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever handles',
      popupLabel: 'Lever Door Handles',
      nocoId: 132,
    },
    roundHandles: {
      codeAsNumber: 0x726f756e,
      codeAsString: 'roun',
      label: 'Round handles',
      popupLabel: 'Round Door Handles',
      nocoId: 135,
    },
    push : {
      codeAsNumber: 0x70757368,
      codeAsString: 'push',
      label: 'Push door without handles',
      popupLabel: 'Push Door Handles',
      nocoId: 136,
    },
  };


  public static TOILET_FLUSH_HANDLE_TYPE: {

    flush: IEnumeratedConstant,
    pad: IEnumeratedConstant,
    cisternButton: IEnumeratedConstant,
    sensor: IEnumeratedConstant,
  } = {

    flush: {
      codeAsNumber: 0x666c7368,
      codeAsString: 'flsh',
      label: 'Flush Handle',
      popupLabel: 'Flush Handle',
      nocoId: 141,
    },
    pad: {
      codeAsNumber: 0x70757061,
      codeAsString: 'pupa',
      label: 'Large wall-mounted flush pad',
      popupLabel: 'Large wall-mounted flush pad',
      nocoId: 142,
    },
    cisternButton: {
      codeAsNumber: 0x63697374,
      codeAsString: 'cist',
      label: 'Press button on top of cistern',
      popupLabel: 'Press button on top of cistern',
      nocoId: 143,
    },
    sensor: {
      codeAsNumber: 0x736e7372,
      codeAsString: 'snsr',
      label: 'Automatic flush sensor',
      popupLabel: 'Automatic flush sensor',
      nocoId: 144
    },
  };


  //  What type of handle is on the sink? (row: 38)
  public static TOILET_SINK_HANDLE_TYPE: {

    lever: IEnumeratedConstant,
    round: IEnumeratedConstant,
    press: IEnumeratedConstant,
    sensor: IEnumeratedConstant,
  } = {

    lever: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever tap handles',
      popupLabel: 'Lever Handle Taps',
      nocoId: 145,
    },
    round: {
      codeAsNumber: 0x726f756e,
      codeAsString: 'roun',
      label: 'Swirl/Round head tap handles',
      popupLabel: 'Swirl/Round Head Handle Taps',
      nocoId: 146,
    },
    press: {
      codeAsNumber: 0x70726573,
      codeAsString: 'pres',
      label: 'Press taps',
      popupLabel: 'Press Taps',
      nocoId: 147,
    },
    sensor: {
      codeAsNumber: 0x736e7372,
      codeAsString: 'snsr',
      label: 'Sensor/Infrared operated',
      popupLabel: 'Sensor/Infrared Operated Taps',
      nocoId: 148,
    },
  };



  public static ENUMERATED_TYPES: IEnumeratedType[]= [

    // {
    //   // 'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
    //   nocoDbDescription: 'Step-free access routes that connect to this building',
    //   nocoDbId: -1,
    //   typeName: 'FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES',
    //   values: FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES,
    // },


    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=223
      exampleQuestionId: 'Bkp11', // Is there parking available for visitors/employees?
      nocoDbDescription: 'Parking',
      nocoDbId: 1,
      typeName: 'FacilityEnumeratedConstants.CAR_PARK_FACILITIES',
      values: FacilityEnumeratedConstants.CAR_PARK_FACILITIES,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=239
      exampleQuestionId: 'ajeoO', // How do the automatic doors open?
      nocoDbDescription: 'Automatic door opening type',
      nocoDbId: 3,
      typeName: 'FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE',
      values: FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=242
      exampleQuestionId: 'aji50', // What type of door handles are on the entrance door?
      nocoDbDescription: 'Entrance door handles',
      nocoDbId: 4,
      typeName: 'FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE',
      values: FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=270
      exampleQuestionId: 'CjEZ3', // What type of door is there into the alternative accessible entrance?
      nocoDbDescription: 'Alternative accessible entrance door type',
      nocoDbId: 5,
      typeName: 'FacilityEnumeratedConstants.DOOR_TYPE',
      values: FacilityEnumeratedConstants.DOOR_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=298
      exampleQuestionId: 'Mpj27', // What type of cafeteria service is provided?
      nocoDbDescription: 'Cafeteria service type',
      nocoDbId: 6,
      typeName: 'FacilityEnumeratedConstants.CAFETERIA_TYPE',
      values: FacilityEnumeratedConstants.CAFETERIA_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=304
      exampleQuestionId: 'BkzJn', // Are the tables and chairs movable or fixed to the floor?
      nocoDbDescription: 'Tables and chairs mobility',
      nocoDbId: 7,
      typeName: 'FacilityEnumeratedConstants.FURNITURE_TYPE',
      values: FacilityEnumeratedConstants.FURNITURE_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=315
      exampleQuestionId: 'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
      nocoDbDescription: 'Furniture mobility at the most accessible breakout area',
      nocoDbId: 8,
      typeName: 'FacilityEnumeratedConstants.FURNITURE_TYPE',
      values: FacilityEnumeratedConstants.FURNITURE_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=320
      exampleQuestionId: 'QZ_p_', // In the bar/lounge area, are the tables and chairs movable or fixed to the floor?
      nocoDbDescription: 'Bar/lounge\'s tables and chairs mobility',
      nocoDbId: 9,
      typeName: 'FacilityEnumeratedConstants.FURNITURE_TYPE',
      values: FacilityEnumeratedConstants.FURNITURE_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=363
      exampleQuestionId: 'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
      nocoDbDescription: 'Mobility capacity of the meeting room furniture',
      nocoDbId: 10,
      typeName: 'FacilityEnumeratedConstants.FURNITURE_TYPE',
      values: FacilityEnumeratedConstants.FURNITURE_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=373
      exampleQuestionId: 'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
      nocoDbDescription: 'Mobility capacity of the most accessible training room furniture',
      nocoDbId: 11,
      typeName: 'FacilityEnumeratedConstants.FURNITURE_TYPE',
      values: FacilityEnumeratedConstants.FURNITURE_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=380
      exampleQuestionId: 'Cjlgx', // What type of handles are on the general bathroom entrance doors?
      nocoDbDescription: 'General bathroom entrance door handles',
      nocoDbId: 12,
      typeName: 'FacilityEnumeratedConstants.TOILET_DOOR_TYPE',
      values: FacilityEnumeratedConstants.TOILET_DOOR_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=381
      exampleQuestionId: 'D1sTp', // What type of door locks are on the general bathroom doors?
      nocoDbDescription: 'General bathroom door lock types',
      nocoDbId: 13,
      typeName: 'FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE',
      values: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=392
      exampleQuestionId: 'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
      nocoDbDescription: 'Door handles in the most wheelchair accessible bathroom',
      nocoDbId: 15,
      typeName: 'FacilityEnumeratedConstants.TOILET_DOOR_TYPE',
      values: FacilityEnumeratedConstants.TOILET_DOOR_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=393
      exampleQuestionId: 'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
      nocoDbDescription: 'Door locks in the most wheelchair accessible bathroom',
      nocoDbId: 16,
      typeName: 'FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE',
      values: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=397
      exampleQuestionId: 'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
      nocoDbDescription: 'Flush type in the most wheelchair accessible bathroom',
      nocoDbId: 17,
      typeName: 'FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE',
      values: FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=402
      exampleQuestionId: 'Bk2hF', // In the most wheelchair accessible bathroom, what type of handle is on the sink?
      nocoDbDescription: 'Sink handle in the most wheelchair accessible bathroom',
      nocoDbId: 18,
      typeName: 'FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE',
      values: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE,
    },
    // {
    //   // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=413
    //   exampleQuestionId: 'XQATW', // In the case of fire or an emergency, are areas of refuge provided in your building?
    //   nocoDbDescription: 'Refuge areas availability in case of fire or emergency',
    //   nocoDbId: 19,
    //   typeName: 'NULL',
    //   values: null,
    // },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=417
      exampleQuestionId: 'Bk3eB', // Does the building have an accessibility certification, inclusion or universal design standards accreditation?
      nocoDbDescription: 'Accessibility compliance certification, inclusion or universal design standards, building code accreditation',
      nocoDbId: 20,
      typeName: 'FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION',
      values: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION,
    },
    // {
    //   // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=510
    //   TV9oQ ... removed ?
    //     nocoDbDescription: 'Push pad button location for the main entrance',
    //   nocoDbId: 23,
    // },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=238
      exampleQuestionId: 'BkqCL', // What type of door is there into the main entrance?
      nocoDbDescription: 'Door handle type on the main entrance door',
      nocoDbId: 24,
      typeName: 'FacilityEnumeratedConstants.DOOR_TYPE',
      values: FacilityEnumeratedConstants.DOOR_TYPE,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=599
      exampleQuestionId: 'g79yu', // Are there steps or ramps up to the stage/presenter area?
      nocoDbDescription: 'steps or ramps up to the presenter area',
      nocoDbId: 44,
      typeName: 'FacilityEnumeratedConstants.STAGE_ACCESS',
      values: FacilityEnumeratedConstants.STAGE_ACCESS,
    },
    {
      // http://localhost:8080/dashboard/#/nc/p_y2caklpb0nx2ds/table/EvaluationQuestion?rowId=583
      exampleQuestionId: 'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
      nocoDbDescription: 'Step-free access routes that connect to this building',
      nocoDbId: 45,
      typeName: 'FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES',
      values: FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES,
    },

  ];

  public static findEnumerateType( enumeratedValue: IEnumeratedConstant ): IEnumeratedType|null {


    if( !enumeratedValue.canonicalId ) {

      return null;
    }

    for( const enumeratedType of FacilityEnumeratedConstants.ENUMERATED_TYPES ) {

      for( const value of Object.values( enumeratedType.values )) {

        const candidate = value as IEnumeratedConstant;

        if( !enumeratedValue.canonicalId ) {

          continue;
        }

        if( candidate.canonicalId === enumeratedValue.canonicalId ) {

          return enumeratedType;
        }

      }
    }

    return null;
  }

  private static _visitEnumeratedTypes( visitor: ( enumeratedType: IEnumeratedType ) => void, skipDuplicates: boolean = true) {

    const visited: { [typeName: string]: IEnumeratedType; } = {};

    for( const enumeratedType of FacilityEnumeratedConstants.ENUMERATED_TYPES ) {

      if( skipDuplicates && visited[enumeratedType.typeName] ) {
        continue;
      }
      visitor( enumeratedType );
      visited[enumeratedType.typeName] = enumeratedType;
    }

  }

  public static initCanonicalIds() {

    FacilityEnumeratedConstants._visitEnumeratedTypes( (enumeratedType: IEnumeratedType) => {

      for( const key of Object.keys( enumeratedType.values )) {

        const aEnum = enumeratedType.values[key] as IEnumeratedConstant;

        aEnum.canonicalId = `${enumeratedType.typeName}.${key}`;

        if( 'Lever Door Handles (a)' === aEnum.popupLabel ) {

          console.log( 'aEnum', aEnum );
        }
      }
    });

  }

  public static printCode( code: string ) {


    const numberCode = EnumeratedConstantReference.asciiCodeToNumber( code ).toString(16);

    console.log( `'${code}' -> 0x${numberCode}`)
  }


  public static getAllEnums(): IEnumeratedConstant[] {

    const answer = [
      BankingEnumeratedConstants.YES,
      BankingEnumeratedConstants.NO,
      BankingEnumeratedConstants.NOT_APPLICABLE,
    ];

    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.CAFETERIA_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.CAR_PARK_FACILITIES, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.DOOR_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.FURNITURE_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.STAGE_ACCESS, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.STEP_FREE_ACCESS_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.TOILET_DOOR_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE, answer );
    // EnumeratedConstantReference.addEnumsFromObject( FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE, answer );

    FacilityEnumeratedConstants._visitEnumeratedTypes( (enumeratedType: IEnumeratedType) => {

      EnumeratedConstantReference.addEnumsFromObject( enumeratedType.values, answer );
    });

    // console.log( 'answer', answer  );
    return answer;
  }

  private static _addEnumeratedConstants( from: any, target: { [key: string]: IEnumeratedConstant; } ) {

    for( const propertyName of Object.keys( from ) ) {

      const enumeratedConstant = from[propertyName];
      const codeAsNumber =  enumeratedConstant.codeAsNumber;
      if( codeAsNumber ) {
        target[`${codeAsNumber}`] = enumeratedConstant;
      }
    }
  }


  public static printCodes() {


    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION', FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_DIRECTION', FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE', FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.CAFETERIA_TYPE', FacilityEnumeratedConstants.CAFETERIA_TYPE);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.CAR_PARK_FACILITIES', FacilityEnumeratedConstants.CAR_PARK_FACILITIES);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.DOOR_TYPE', FacilityEnumeratedConstants.DOOR_TYPE);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.FURNITURE_TYPE', FacilityEnumeratedConstants.FURNITURE_TYPE);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.STAGE_ACCESS', FacilityEnumeratedConstants.STAGE_ACCESS );
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.STEP_FREE_ACCESS_TYPE', FacilityEnumeratedConstants.STEP_FREE_ACCESS_TYPE );
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES', FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES );
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.TOILET_DOOR_TYPE', FacilityEnumeratedConstants.TOILET_DOOR_TYPE);
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE', FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE );
    // EnumeratedConstantReference.printEnum('FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE', FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE);

    FacilityEnumeratedConstants._visitEnumeratedTypes( (enumeratedType: IEnumeratedType) => {

      EnumeratedConstantReference.printEnum(enumeratedType.typeName, enumeratedType.nocoDbId, enumeratedType.values);
    }, false );

  }


  public static getAllEnumeratedConstants() {

    const answer: { [key: string]: IEnumeratedConstant; } = {};

    answer[`${FacilityEnumeratedConstants.YES.codeAsNumber}`] = FacilityEnumeratedConstants.YES;
    answer[`${FacilityEnumeratedConstants.NO.codeAsNumber}`] = FacilityEnumeratedConstants.NO;
    answer[`${FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber}`] = FacilityEnumeratedConstants.NOT_APPLICABLE;

    FacilityEnumeratedConstants._visitEnumeratedTypes( (enumeratedType: IEnumeratedType) => {

      FacilityEnumeratedConstants._addEnumeratedConstants( enumeratedType.values, answer );
    });

    return answer;
  }

}


FacilityEnumeratedConstants.initCanonicalIds();
