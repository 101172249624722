import {BaseReference} from "../../model/BaseReference";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";


export interface INocoQuestionType {

  Id: number;
  Type: string;
}

export class NocoQuestionType  extends BaseReference<INocoQuestionType> {

  public static readonly ENUMERATED_TYPE_ID = 1;

  public isBoolean() {

    if( 'Yes/No' === this.value.Type) {
      return true;
    }
    return false;
  }

  public isEnumerated() {

    if( 'Enumerated' === this.value.Type) {
      return true;
    }
    return false;
  }

  public isNumeric() {

    if( 'Numeric' === this.value.Type) {
      return true;
    }
    return false;
  }


  public isMeasurement() {

    if( 'Measurement' === this.value.Type) {
      return true;
    }
    return false;

  }

  public isPhoto() {

    if( 'Photo' === this.value.Type) {
      return true;
    }
    return false;
  }

  public isTernary() {

    if( 'Yes/No/Na' === this.value.Type) {
      return true;
    }
    return false;
  }

  public isTextual() {

    if( 'Textual' === this.value.Type) {
      return true;
    }
    return false;
  }


  protected onSetValue(value: INocoQuestionType | null) {
  }

  constructor( value: INocoQuestionType | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }
}

export class NocoQuestionTypeSet {

  questionTypes: NocoQuestionType[] = [];
  questionTypesByKey: {[id: number]: NocoQuestionType} = {};


  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoQuestionType>> {

    return proxy.getView<INocoQuestionType>( 'QuestionType' );
  }


  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoQuestionTypeSet> {

    const value = await NocoQuestionTypeSet.getValue( proxy );
    return new NocoQuestionTypeSet( value );
  }


  public constructor( public value: IListingReponse<INocoQuestionType> ) {

    for( const rowValue of value.list ) {

      const reference = new NocoQuestionType( rowValue );
      this.questionTypes.push( reference );
      this.questionTypesByKey[rowValue.Id] = reference;
    }

  }

}
