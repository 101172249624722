import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SectionAnswers1} from '../../../javascript.lib.mojo-base/model/module/SectionAnswers1';
import {QuestionCentimetersComponent} from '../question-centimeters/question-centimeters';
import {QuestionIntegerComponent} from '../question-integer/question-integer';
import {QuestionReference} from '../../../javascript.lib.mojo-base/model/Question';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
})
export class QuestionListComponent implements OnInit {


  @Input() sectionAnswers: SectionAnswers1 = null;
  @Input() propertyKey: string = null;

  @ViewChildren(QuestionCentimetersComponent) questionCentimetersComponents: QueryList<QuestionCentimetersComponent>;
  @ViewChildren(QuestionIntegerComponent) questionIntegerComponents: QueryList<QuestionIntegerComponent>;

  public isTest = environment.isTest || environment.isDevelopment;
  // public isTest = false;


  questionTypes = {
    TYPE_BOOLEAN: QuestionReference.TYPE_BOOLEAN,
    TYPE_CM_MEASUREMENT: QuestionReference.TYPE_CM_MEASUREMENT,
    TYPE_ENUM: QuestionReference.TYPE_ENUM,
    TYPE_FLOAT: QuestionReference.TYPE_FLOAT,
    TYPE_INTEGER: QuestionReference.TYPE_INTEGER,
    TYPE_LINE: QuestionReference.TYPE_LINE,
    TYPE_PHOTO: QuestionReference.TYPE_PHOTO,
    TYPE_TERNARY: QuestionReference.TYPE_TERNARY,
    TYPE_TEXT: QuestionReference.TYPE_TEXT,
  };


  hasIssues(): boolean {

    for( const questionCentimetersComponent of this.questionCentimetersComponents.toArray() ) {

      if( questionCentimetersComponent.hasIssues() ) {
        return true;
      }
    }
    for( const questionIntegerComponent of this.questionIntegerComponents.toArray()) {

      if( questionIntegerComponent.hasIssues() ) {
        return true;
      }
    }

    return false;
  }

  ngOnInit() {
  }

  constructor() { }


}
