import {BaseReference} from "../model/BaseReference";
import {IHotelUser} from "../hotel/model/HotelUser";
import {environment} from "../../environments/environment";
import {EProductType} from "../product.common/ProductType";


export interface IEvaluationUserMetaData {
  version: number[];
}

export interface IEvaluationUser {

  email: string;
  firstName?: string;
  fullName?: string|string[];
  hotelKey?: string;
  jobTitle?: string;
  lastName?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
  primaryPropertyKey?: string;
  propertyKeys?: {[key: string]: string};
  productPropertyKeys?: {[key: string]: {[key: string]: string}};
}

// taken from `IMerckEvaluationUser` in `node.app.csv-to-json/src/model/MerckEvaluationUser.ts`
export interface IEvaluationUser2 extends IEvaluationUser {

  email: string;
  fullName: string[];

  propertyKeys: {[key: string]: string};
  productPropertyKeys: {[key: string]: {[key: string]: string}};

  _meta: IEvaluationUserMetaData;
}


export class EvaluationUser extends BaseReference<IEvaluationUser> {

  fullName: string;
  propertyKeys: string[] = [];

  public clone(): EvaluationUser {

    const value: IEvaluationUser = Object.assign( {}, this.value );
    return EvaluationUser.build( value, this.userUid );
  }


  public setFullName( fullName: string ) {

    this.fullName = fullName;
    const tokens = fullName.split( ' ');
    this.value.fullName = tokens;
  }


  public static build( value: any, userUid: string = null ): EvaluationUser {

    console.log( 'userUid', userUid );
    console.log( 'value', value );

    // at least version 2
    if( value._meta ) {

      return new EvaluationUser( value as IEvaluationUser2, userUid );
    }

    // version 1
    const evaluationUser1: IEvaluationUser = value as IEvaluationUser;
    const evaluationUser2: IEvaluationUser2 = {

      _meta: {
        version: [2,0,0]
      },
      email: evaluationUser1.email,
      fullName: [],
      propertyKeys: {},
      productPropertyKeys: {},
    };

    if( evaluationUser1.propertyKeys ) {

      evaluationUser2.propertyKeys =  evaluationUser1.propertyKeys;
    } else {

      evaluationUser2.propertyKeys = {};
      if( evaluationUser1.primaryPropertyKey ) {

        evaluationUser2.propertyKeys[evaluationUser1.primaryPropertyKey] = evaluationUser1.primaryPropertyKey;
      }
    }
    if( evaluationUser1.productPropertyKeys ) {

      evaluationUser2.productPropertyKeys =  evaluationUser1.productPropertyKeys;
    } else {
      evaluationUser2.productPropertyKeys = {};
      if( evaluationUser1.primaryPropertyKey ) {

        evaluationUser2.productPropertyKeys[environment.productConfig.productType][evaluationUser1.primaryPropertyKey] = evaluationUser1.primaryPropertyKey;
      }
    }

    return new EvaluationUser( evaluationUser2, userUid );
  }


  protected onSetValue(value: IEvaluationUser | null) {

    this.fullName = null;
    this.propertyKeys = [];

    if( value ) {

      {
        if( value.fullName ) {

          // facilities era ...

          if( 'string' === typeof value.fullName ) {

            this.fullName = value.fullName;
          } else {

            // assume it's an array
            this.fullName = value.fullName.join( ' ' );
          }
        } else if( value.firstName && value.lastName ) {

          // hotel product era ..
          this.fullName = `${value.firstName} ${value.lastName}`;
        } else {

          // fall back to the email address ...
          const indexOfAt = value.email.indexOf( "@" );
          const emailName = value.email.substring( 0, indexOfAt );
          const emailNameTokens = emailName.split( '.');
          this.fullName = emailNameTokens.join( ' ');
        }

      }


      {

        const propertyKeys = [];
        let primaryPropertyKey = value.primaryPropertyKey;
        if( primaryPropertyKey ) {

          propertyKeys.push( primaryPropertyKey );
        } else if( value.hotelKey ) {

          primaryPropertyKey = value.hotelKey;
          propertyKeys.push( primaryPropertyKey );
        }

        // NOTE: 'primaryPropertyKey' could be null/undefined here

        if( value.productPropertyKeys ) {

          for( let propertyKey in value.productPropertyKeys[environment.productConfig.productType] ) {

            if( propertyKey !== primaryPropertyKey ) {

              propertyKeys.push( propertyKey );
            }
          }
        } else if( value.propertyKeys ) {
          for( let propertyKey in Object.keys( value.propertyKeys )) {

            if( propertyKey !== primaryPropertyKey ) {

              propertyKeys.push( propertyKey );
            }
          }
        }

        this.propertyKeys = propertyKeys;

      }

    }
  }

  getPrimaryPropertyKey(): string|null {

    if( this.propertyKeys.length ) {

      return this.propertyKeys[0];
    }
    return null;

  }

  private constructor(value: IEvaluationUser|null, public userUid: string = null) {

    super(value);
    if( value ) {
      this.value = value;
    }
  }

}

