import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {QuestionReference} from "../../../javascript.lib.mojo-base/model/Question";
import {Subscription} from "rxjs";
import {AnswerReference} from "../../../javascript.lib.mojo-base/model/Answer";
import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {DialogData, QuestionBooleanDialogComponent} from "./question-boolean-dialog/question-boolean-dialog";
import {MatDialog} from "@angular/material/dialog";
import {DialogConfig} from "../../angular/DialogConfig";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";

/**
 * Generated class for the QuestionBooleanComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'question-boolean',
  templateUrl: 'question-boolean.html',
  styleUrls: ['question-boolean.scss']
})
export class QuestionBooleanComponent implements OnInit, AfterViewInit, OnDestroy {

  private _log = LoggerFactory.build( 'QuestionBooleanComponent' );

  @Input() answer: AnswerReference;


  @Input() trueLabel: string = 'Yes';
  @Input() falseLabel: string = 'No';

  _disabled: boolean = false;



  private _dependantSubscription: Subscription|null = null;


  @Input() set disabled(value: boolean) {

    this._disabled = value;

    if( this._disabled ) {

      this.answer.value.value = false;

      // hacky way to get the subscription to trigger
      this.answer.value = this.answer.value;
    }
  }


  @Output() change = new EventEmitter<QuestionBooleanComponent>();



  onOpenDialog(): void {

    const data: DialogData = {
      answer: this.answer
    };

    const dialogRef = this.dialog.open(QuestionBooleanDialogComponent, {
      minWidth: DialogConfig.minWidth,
      panelClass: QuestionBooleanDialogComponent.PANEL_CLASS,
      data,
    });

    dialogRef.afterClosed().subscribe(result => {

      this._log.debug('dialog was closed');
    });
  }


  ngOnInit(): void {

    if( 'MpjwN' === this.answer.question.value.key ) {

      this._log.debug( 'MpjwN', 'this.answer', this.answer );
    }

    if( this.answer.question.value.type !== QuestionReference.TYPE_BOOLEAN ) {

      this._log.error( 'ngOnInit', 'this.questionAnswer.question.value.type !== QuestionReference.TYPE_BOOLEAN' );
    }


    if( this.answer.dependant ) {

      this._dependantSubscription = this.answer.dependant.getSubject().subscribe( (dependant: AnswerReference ) => {

        const isTruthy = dependant.isTruthy(this.answer.question.value.dependant);
        // this._log.debug( 'ngOnInit', dependant, 'isTruthy: ' + isTruthy );
        this.disabled = !isTruthy;
      } );
    }

    // this.onOpenDialog();
  }


  ngOnDestroy(): void {

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }

  ngAfterViewInit(): void {
  }

  constructor( public dialog: MatDialog ) {
  }


}
