import {IQuestion, QuestionReference} from "./Question";
import {BaseReference} from "./BaseReference";
import { QuestionKey } from "./QuestionKey";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";


export class QuestionSet extends BaseReference<IQuestion[]> {

  private _log: ILogger = LoggerFactory.build( 'QuestionSet' );

  public questionByKey: {[key: QuestionKey]: QuestionReference} = {};
  public questions: QuestionReference[] = [];
  private _questionByNocoDbId: {[nocoDbId: number]: QuestionReference} = null;

  public static joinValues( a: IQuestion[], b: IQuestion[] ): IQuestion[] {

    const questionByKey: {[key: QuestionKey]: IQuestion} = {};

    for( const question of a ) {

      questionByKey[question.key] = question;
    }

    for( const question of b ) {

      questionByKey[question.key] = question;
    }

    return Object.values( questionByKey );
  }

  private _getQuestionByNocoDbId() {

    if( !this._questionByNocoDbId ) {
      this._questionByNocoDbId = {};

      for( const question of this.questions ) {
        if( question.value.nocoDbId ) {
          this._questionByNocoDbId[question.value.nocoDbId] = question;
        }
      }
    }
    return this._questionByNocoDbId;
  }

  public getQuestionByNocoDbId( nocoDbId: number ): QuestionReference|null {

    const questionByNocoDbId = this._getQuestionByNocoDbId();
    return questionByNocoDbId[ nocoDbId ];
  }

  public getMaximumScore( keys: QuestionKey[] ) {

    let accumulator = 0;

    for( const key of keys ) {

      const question = this.questionByKey[key];
      if( !question ) {

        this._log.warn( '!question; question not found', 'key', key );

      } else {

        accumulator += question.value.maximumScore;
      }
    }

    return accumulator;
  }


  protected onSetValue(value: IQuestion[] | null) {

    this.questionByKey = {};
    this.questions = [];

    for( const questionValue of value ) {

      const question = new QuestionReference( questionValue );
      this.questions.push( question );
      this.questionByKey[questionValue.key] = question;
    }

  }

  constructor( value: IQuestion[] ) {

    super(value);
    this.value = value;
  }




}

