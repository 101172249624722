import {Component } from '@angular/core';
import {ImageHelper} from "../../../util/ImageHelper";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AnswerReference} from "../../../../javascript.lib.mojo-base/model/Answer";


@Component({
  selector: 'help-dialog',
  templateUrl: 'help-dialog.html',
  styleUrls: ['help-dialog.scss']
})
export class HelpDialog {


  static pageParams = {

    answer: null,
  };

  answer: AnswerReference;
  helpText: string;
  cmMeasurementMaxValue: number = null;
  cmMeasurementMinValue: number = null;



  falseyDependentAnswer: AnswerReference = null;


  helpImagePaths: string[] = [];


  dismiss() {

    this.dialogRef.close();
  }

  static open(dialog: MatDialog, answer: AnswerReference) {

    HelpDialog.pageParams.answer = answer;

    const methodAnswer = dialog.open(HelpDialog, {
      data: {
        answer
      },
      panelClass: 'help-dialog'
    });

    return methodAnswer;
  }


  constructor( public dialogRef: MatDialogRef<HelpDialog> ) {

    this.answer = HelpDialog.pageParams.answer;
    const question = this.answer.question;

    this.helpText = question.value.helpText;

    {
      const dependant = this.answer.dependant;
      if( dependant ) {

        if( !dependant.isTruthy(this.answer.question.value.dependant) ) {

          this.falseyDependentAnswer = dependant;
        }
      }

    }

    if( question.isCmMeasurement ) {
      if( question.value.type2 && question.value.type2.typeCmMeasurement ) {

        const maxValue = question.value.type2.typeCmMeasurement.maxValue;
        if( null != maxValue && Number.MAX_VALUE !== maxValue) {

          this.cmMeasurementMaxValue = maxValue;
        }
        const minValue = question.value.type2.typeCmMeasurement.minValue;
        if( null != minValue ) {

          this.cmMeasurementMinValue = minValue;
        }
      }
    }
    if( question.value.helpImages ) {

      for( const helpImage of question.value.helpImages ) {

        const imagePath = ImageHelper.resolve( helpImage );
        this.helpImagePaths.push( imagePath );
      }

    } else {

      const imagePath = ImageHelper.resolve( question.value.helpImage );
      this.helpImagePaths.push( imagePath );
    }

  }

}
