import {Component, Input, OnInit} from '@angular/core';
import {BrowserAppEvaluationToolInfo} from "../../../BrowserAppEvaluationToolInfo";
import {faHome, faHotel, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BaseApplicationContext} from "../../services.application-context/BaseApplicationContext";
import {BaseSessionContext} from "../../service.session-context/BaseSessionContext";
import {BaseSideMenuModel} from "../../service.side-menu-model/BaseSideMenuModel";
import {MatSidenav} from "@angular/material/sidenav";
import {environment} from "../../../environments/environment";
import {BaseLogger} from "../../../javascript.lib.mojo-base/log/BaseLogger";


export interface IMenuItem {

  disabled(): boolean;

  icon: IconDefinition;

  label(): string;

  onClick(): Promise<void>;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input('content') content:any;
  @Input() homeDisabled:boolean = false;
  @Input() sidenav: MatSidenav = null;
  public version: string = "?";

  public static icons = {
    faHome,
    faHotel,
    faSignOutAlt,
  };

  onClick( menuItem: IMenuItem ) {

    menuItem.onClick();

    if( this.sidenav ) {
      this.sidenav.toggle();
    }
  }

  ngOnInit() {

    const codeVersion = new BrowserAppEvaluationToolInfo().version;
    const questionsVersion = environment.product.version;
    this.version = `${codeVersion} / ${questionsVersion} / ${BaseLogger.CORRELATION_ID}`;
  }

  constructor( public router: Router,
               public applicationContext: BaseApplicationContext,
               public sessionContext: BaseSessionContext,
               public model: BaseSideMenuModel,
  ) {
  }

}
