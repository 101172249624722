import {AnswerReference, IAnswer} from './Answer';
import {EvaluationSection} from './module/EvaluationSection';
import {QuestionReference} from './Question';
import {IScore} from "../hotel/model/accessibility/Score";
import { DefaultScoreCalculator } from './score/DefaultScoreCalculator';
import {LoggerFactory} from "../log/LoggerFactory";


export class AspectAnswers {

  private _log = LoggerFactory.build( 'AspectAnswers' );

  private keyToAnswers: { [key: string]: AnswerReference } = {};
  private _completed  = false;
  private _score: IScore = null;


  getAnswer( question: QuestionReference ): AnswerReference {


    const questionKey = question.value.key;
    let reference = this.keyToAnswers[ questionKey ];

    if( !reference ) {

      const answerValue = this.value[ questionKey ];
      if( answerValue ) {

        reference = new AnswerReference( question, answerValue );
      } else {

        reference = new AnswerReference( question );
        this.value[ questionKey ] = reference.value;
      }

      if( question.value.dependant ) {

        const dependantQuestion = this.aspectQuestions.questionsByKey[ question.value.dependant.questionKey ];
        if( !dependantQuestion ) {

          console.warn( "!dependantQuestion", "question.value.dependant.questionKey: " + question.value.dependant.questionKey )
        } else {

          const dependantAnswer = this.getAnswer( dependantQuestion );
          reference.dependant = dependantAnswer;

        }

      }

      this.keyToAnswers[ questionKey ] = reference;
    }

    return reference;
  }


  scrubMarkupTagsFromText( originalValue: string ) {

    let answer = originalValue;
    {
      const openingTag = /<([_a-zA-Z0-9]*)>/i; // https://regex101.com/r/NMrXnd/1
      let matches = originalValue.match( openingTag );
      if( matches ) {
        for( const match of matches ) {

          answer = answer.replace( `<${match}>`, `-${match}-` );
        }
      }
    }
    {
      const closingTag = /<\/([_a-zA-Z0-9]*)>/i; // https://regex101.com/r/tKJTNv/1
      let matches = originalValue.match( closingTag );
      if( matches ) {
        for( const match of matches ) {

          answer = answer.replace( `</${match}>`, `-${match}-` );
        }
      }
    }

    return answer;
  }

  // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)
  scrubMarkupTagsFromTextAnswers() {

    for( const questionKey of Object.keys( this.keyToAnswers )) {
      const answer: AnswerReference = this.keyToAnswers[questionKey];
      if( answer.question.isText ) {
        if( answer.value.value && 'string' == typeof answer.value.value ) {

          answer.value.value = this.scrubMarkupTagsFromText( answer.value.value );
        }
      }
    }

  }

  willEditAnswers(): void {

    this._completed = null;
    this._score = null;
  }

  /**
   * @deprecated call 'answer.getScore()'
   */
  public getScoreForAnswer( answer: AnswerReference ): number {

    return DefaultScoreCalculator.INSTANCE.getScore( answer );
  }

  getScore( debug: boolean = false ): IScore|null {

    if( !this.isCompleted() ) {

      return null;
    }

    this._score = {

      numerator: 0,
      denominator: 0
    };

    for( const question of this.aspectQuestions.questions ) {

      this._score.denominator += question.value.maximumScore;

      if( Number.isNaN( this._score.denominator )) {

        this._log.error( 'Number.isNaN( this._score.denominator )', 'question', question );
      }

      const answer = this.getAnswer(question);

      this._score.numerator += DefaultScoreCalculator.INSTANCE.getScore( answer );
      if( Number.isNaN( this._score.numerator )) {

        this._log.error( 'Number.isNaN( this._score.numerator )', 'question', question );
      }
    }

    return this._score;

  }

  isCompleted(): boolean {

    if( this._completed) {

      return true;
    }

    // this._log.debug( `Unanswered questions for ${this.aspectQuestions.value.name} (${this.aspectQuestions.value.firebaseAspectId}) ... `);
    // for( const question of this.aspectQuestions.questions ) {
    //   if( !this.value[question.value.key] ) {
    //     // this._log.debug( `this.value[question.value.key]`, this.value[question.value.key] );
    //     this._log.debug( `${question.value.key} (${question.value.nocoDbId})`);
    //   }
    // }
    // this._log.debug( `... end`);

    // less answers than questions
    if( Object.keys( this.value ).length < this.aspectQuestions.questions.length ) {

      return false;
    }

    // there is a bug here, if questions are removed but new answers are introduced that have not been answerwd,
    // then 'isCompleted' will return true

    this._completed = true;
    return this._completed;
  }

  constructor( public value: { [key: string]: IAnswer }|null,
               public aspectQuestions: EvaluationSection ) {

    if( !value ) {

      this.value = {};
    }
  }

}
