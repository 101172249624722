import {Component, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {HelpDialog} from "./component.help-dialog/help-dialog";
import {AnswerReference} from "../../../javascript.lib.mojo-base/model/Answer";

/**
 * Generated class for the HelpComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'help',
  templateUrl: 'help.html',
  styleUrls: ['help.scss']
})
export class HelpComponent {

  @Input() answer: AnswerReference;

  onShowHelp() {

    HelpDialog.open( this.dialog, this.answer );
  }

  constructor( public dialog: MatDialog) {
  }

}
