import {IQuestion, QuestionReference} from './Question';
import {AnswerReference, IAnswer} from './Answer';

export interface IQuestionAnswer {

  question: QuestionReference;
  answer: AnswerReference;

}

/**
 * @deprecated use Answer as a IQuestionAnswer
 */
export class QuestionAnswer implements IQuestionAnswer {

  question: QuestionReference;
  answer: AnswerReference;


  constructor( question: IQuestion|QuestionReference, answer: IAnswer|AnswerReference = null ) {


    if ( question instanceof QuestionReference ) {

      this.question = question;
    } else {

      this.question = new QuestionReference( question );
    }


    if ( answer ) {

      if( answer instanceof AnswerReference ) {

        this.answer = answer;
      } else {

        this.answer = new AnswerReference( this.question, answer );
      }
    } else {

      this.answer = new AnswerReference( this.question );
    }

  }

}
