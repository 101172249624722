


export class BrowserAppEvaluationToolInfo {

  public version = '2023.7.11.3';

  constructor() {
  }

}
