// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/product.common/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-4a4c4c.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,


  firebaseConfig: {
    apiKey: "AIzaSyBgImuP8lvJBAsR9NPeRij6rr_hd3XJi0Y",
    appId: "1:1037495781485:web:f832f87974386a9ae11764",
    authDomain: "facilities-4a4c4c.firebaseapp.com",
    databaseURL: "https://facilities-4a4c4c-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "1037495781485",
    projectId: "facilities-4a4c4c",
    storageBucket: "facilities-4a4c4c.appspot.com",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-4a4c4c.cloudfunctions.net',

  // nocoDbConfig: {
  //   // httpServer: 'http://localhost:8080',
  //   // httpServer: 'http://spot.local:8080',
  //   httpServer: 'https://mobilitymojo-spot.com',
  //   user: 'test@mobilitymojo.com',
  //   password: 'test@mobilitymojo.com',
  //   authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  // },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    productType: EProductType.facilities,

  },

  // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
  product:  FacilityAccessibilityProduct4.INSTANCE,

  supportsSubmitButton: false

};
