import {ScoreRuleSet} from "../../javascript.lib.mojo-base/model/score/ScoreRuleSet";
import {AndCondition} from "../../javascript.lib.mojo-base/model/score/AndCondition";
import {ValueCondition} from "../../javascript.lib.mojo-base/model/score/ValueCondition";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";
import {NotCondition} from "../../javascript.lib.mojo-base/model/score/NotCondition";


export class FacilityScoreRules3 {


  public static readonly VALUE: ScoreRuleSet = new ScoreRuleSet([
    {
      key: "rule-22.11.a",
      applicableQuestions: [
        'Mpj2n', // Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?
        'BkqAn', // Are there designated wheelchair accessible parking spaces available?
        'BkqAx', // How many wheelchair parking spaces are provided?
        'ajdl0', // Is there a step-free and unobstructed route from the designated accessible parking space(s) to the most accessible building entrance?
        'BkqA7', // Is there an accessible entrance leading from the indoor car park to the main building?
      ],
      guard: ValueCondition.enumHasValue(
        'Bkp11', // Is there parking available for visitors/employees?
        FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
      ),
      description: "No marks for questions that depend on accessible parking being available",
      policy: 'noMarks',
    },
    {
      key: "rule-22.11.b",
      applicableQuestions: [
        'Mpj2n', // Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?
      ],
      guard: new AndCondition(
        [
          NotCondition.not(
            ValueCondition.enumHasValue(
              'Bkp11', // Is there parking available for visitors/employees?
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
            )),
          ValueCondition.booleanHasValue(
            'MpjwN', // Are parking charges in place?
            false
          )
        ]
      ) ,
      description: "If there is parking but no charges for the parking, then give full marks for the 'payment machines set at a reachable height'",
      policy: 'fullMarks',
    },

    {
      key: "rule-22.11.c",
      applicableQuestions: [
        'Bk0AJ', // Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.
        'ajmsU', // Are the lift/elevator call buttons reachable from a seated position and free from obstructions?
        'ajm9M', // Do the lift/elevator call buttons have visual indicators?
        'ajnKo', // Inside the lift/elevator, are there visual indicators showing the current floor position?
        'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
        'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
        'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
        'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
        'Bk0BF', // Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?
        'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
        'ajnX6', // Does the emergency alarm in the lift/elevator have braille or raised letters?
        'XP_vG', // Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?
        'TgYOI', // Is there clear signage to indicate that lift/elevators can or cannot be used in an emergency situation?
      ],
      guard: new AndCondition(
        [
          ValueCondition.booleanHasValue(
            'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
            false
          ),
          ValueCondition.booleanHasValue(
            'Bkz__', // Is there a lift/elevator in the building?
            false
          )
        ]
      ) ,
      description: "No marks for questions related to a lift when there is no lift but the building is multi-story",
      policy: 'noMarks',
    },
    // {
    //   key: "#3",
    //   applicableQuestions: [
    //     'CjqNt', // Is there a sink at a lower height in the general bathrooms?
    //   ],
    //   guard: ValueCondition.booleanHasValue(
    //     'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
    //     false
    //   ),
    //   description: "This looks like a mistake",
    //   policy: 'noMarks',
    // },
    {
      key: "rule-22.11.d",
      applicableQuestions: [
        'Bk2fN', // Do the accessible bathroom doors have braille, raised lettering, or appropriate symbols to identify the bathroom type?
        'Bk2fr', // What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)
        'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
        'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
        'Bk2f_', // In the most wheelchair accessible bathroom, what is the width of the door? (In cm)
        'Bk2gJ', // In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?
        'Bk2gT', // In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)
        'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
        'Bk2gn', // In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet?
        'Bk2gx', // In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?
        'CjqNt', // Is there a sink at a lower height in the accessible bathroom?
        'Bk2g7', // In the most wheelchair accessible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?
        'Bk2hF', // In the most wheelchair accessible bathroom, what type of handle is on the sink?
        'Bk2hP', // In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?
        'Bk2hZ', // Is there a roll in shower on the premises?
      ],
      guard: ValueCondition.booleanHasValue(
        'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        false
      ),
      description: "no marks for any questions related to the accessible bathroom when there is none",
      policy: 'noMarks',
    },
    {
      key: "rule-22.11.e",
      applicableQuestions: [
        'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
        'CjEZ3', // What type of door is there into the alternative accessible entrance?
        'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
        'BkqBt', // Is the alternative accessible entrance clearly signposted?
        'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
      ],
      guard: new AndCondition([
        ValueCondition.enumHasValue(
          'BkqCl', // What type of door is there into the main entrance?
          FacilityEnumeratedConstants.DOOR_TYPE.revolving
        ),
        ValueCondition.booleanHasValue(
          'BkqCV', // Is there an alternative accessible entrance?
          false
        )
      ]),
      description: "no marks for questions related alternate accessible entrance when the primary door is revolving and there is no alternate accessible entrance",
      policy: 'noMarks',
    },
    {
      key: "rule-23.04.a",
      applicableQuestions: [
        'Bk0AJ', // Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.
        'ajmsU', // Are the lift/elevator call buttons reachable from a seated position and free from obstructions?
        'ajm9M', // Do the lift/elevator call buttons have visual indicators?
        'goXHg', // Are the first and last steps clearly marked with a different colour or tactile ground surface indicators?
        'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
        'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
        'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
        'Bk0BF', // Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?
        'ajnKo', // Inside the lift/elevator, are there visual indicators showing the current floor position?
        'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
        'ajnX6', // Does the alarm button in the lift/elevator have braille, raised letters, or appropriate symbols?
        'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
        'TgYOI', // Is there clear signage to indicate if lift/elevators can or cannot be used in an emergency situation?
        'XP_vG', // Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?
      ],
      guard: new AndCondition(
        [
          ValueCondition.booleanHasValue(
            'ajmd8', // Are all your facilities on the ground level without the need to use an elevator to get to any areas or amenities?
            true
          ),
          ValueCondition.booleanHasValue(
            'Bkz__', // Is there a lift/elevator in the building?
            false
          )
        ]
      ) ,
      description: "If the all your facilities are on the ground level and there is no lift/elevator in the building",
      policy: 'fullMarks',
    },

    // {
    //   key: "template",
    //   applicableQuestions: [
    //   ],
    //   guard: new OrCondition(
    //     [
    //       ValueCondition.booleanHasValue(
    //         'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
    //         false
    //       )
    //     ]
    //   ) ,
    //   description: "description",
    //   policy: 'noMarks',
    // },

  ]);
}
