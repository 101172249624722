import {BaseReference} from "../BaseReference";
import {QuestionKey} from "../QuestionKey";
import {IDependantDescriptor} from "../Question";
import {IPageQuestions} from "./PageQuestions";

export interface ISectionQuestions2 {

  dependant?: IDependantDescriptor;

  questionKeys: QuestionKey[];

  /**
   * for identification/persistence
   */
  sectionId?: string;
  subSections: ISectionQuestions2[];

  title: string;

}



export class SectionQuestions2 extends BaseReference<ISectionQuestions2> {


  public static toSectionQuestions1(sectionQuestions2: SectionQuestions2[] ): IPageQuestions[] {

    const answer: IPageQuestions[] = [];


    for( const section of sectionQuestions2 ) {

      answer.push( section.toSectionQuestions1() )
    }

    return answer;
  }

  public toSectionQuestions1(): IPageQuestions {

    const answer: IPageQuestions = {
      headerText: this.value.title,
      questionKeys: this.value.questionKeys
    };

    if( this.value.dependant ) {

      answer.dependant = this.value.dependant;
    }

    return answer;
  }

  protected onSetValue(value: ISectionQuestions2 | null) {
  }

  constructor( value: ISectionQuestions2 ) {

    super(value);
  }

}
