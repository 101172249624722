import {BaseReference} from "../BaseReference";

export interface IEvaluationCompleted {

  userEmail?: string;
  userUid: string;
  when: number;
  whenAsString?: string;
}


export class EvaluationCompleted extends BaseReference<IEvaluationCompleted> {

  public static build( userUid: string, userEmail: string = null ): EvaluationCompleted {

    const value: IEvaluationCompleted = {
      userUid,
      when: new Date().getTime()
    }

    if( userEmail ) {

      value.userEmail = userEmail;
    }

    return new EvaluationCompleted( value );
  }

  protected onSetValue(value: IEvaluationCompleted | null) {
  }



  public constructor( value: IEvaluationCompleted ) {

    super( value );
    if ( value ) {

      this.value = value;
    }
  }

}




