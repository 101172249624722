import {BaseReference} from "../../model/BaseReference";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {INocoAttachment, NocoAttachment} from "./NocoAttachment";
import {IListingReponse} from "../ListingReponse";
import {INocoEnumeratedValue} from "./NocoEnumeratedValue";


export interface INocoEvaluationQuestionImage {

  Id: number;
  Image: string;
  ImageName: string;
  ImageNameOld: string;
  JasonsFilename: string|null;

}

export class NocoEvaluationQuestionImage extends BaseReference<INocoEvaluationQuestionImage>{




  attachments: NocoAttachment[] = [];


  protected onSetValue(value: INocoEvaluationQuestionImage | null) {

    const log: ILogger = LoggerFactory.build( 'NocoEvaluationQuestionImage' );

    const attachmentsKey = 'SVG file';
    const attachments = value[attachmentsKey];


    let imagesValue: INocoAttachment[] = [];

    if( attachments ) {

      // NocoDB ^0.98.1
      if( 'string' == typeof attachments ) {

        imagesValue = JSON.parse( attachments ) as INocoAttachment[];
      } else { // NocoDB ^0.101.2

        imagesValue = attachments as INocoAttachment[];
      }
    }

    this.attachments = NocoAttachment.build( imagesValue, this.value.Id );

  }

  constructor( value: INocoEvaluationQuestionImage | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }

}


export class NocoEvaluationQuestionImageSet {

  private static _log: ILogger = LoggerFactory.build( 'NocoEvaluationQuestionImageSet' );

  evaluationQuestionImages: NocoEvaluationQuestionImage[] = [];
  evaluationQuestionImagesById: {[id: number]: NocoEvaluationQuestionImage} = {};

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEvaluationQuestionImage>> {

    return proxy.getView<INocoEvaluationQuestionImage>( 'EvaluationQuestionImage' );
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEvaluationQuestionImageSet> {

    const value = await proxy.getView<INocoEvaluationQuestionImage>( 'EvaluationQuestionImage' );
    return new NocoEvaluationQuestionImageSet(value);
  }


  public constructor(public value: IListingReponse<INocoEvaluationQuestionImage>) {

    for( const rowValue of value.list ) {

      const reference = new NocoEvaluationQuestionImage( rowValue );
      this.evaluationQuestionImages.push( reference );
      this.evaluationQuestionImagesById[rowValue.Id] = reference;
    }

  }


}
