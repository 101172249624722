import {BehaviorSubject} from "rxjs";
import firebase from 'firebase/compat/app';
import User = firebase.User;
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {IFirebaseError} from "../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {AppStorage} from "../../app/AppStorage";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Watchable} from "../../javascript.lib.mojo-base/util/Watchable";
import ConfirmationResult = firebase.auth.ConfirmationResult;
import {AuthenticatedProxy} from "../firebase/functions/AuthenticatedProxy";
import {HttpClient} from "@angular/common/http";


export enum SessionContextState {

  UserIsAuthenticating = "UserIsAuthenticating",
  UserIsAuthenticated = "UserIsAuthenticated",
  UserDataLoading = "UserDataLoading",
  UserDataLoaded = "UserDataLoaded",
  PropertyDataLoading = "PropertyDataLoading",
  PropertyDataLoaded = "PropertyDataLoaded",
  UserIsReady = "UserIsReady",
  LoggedOut = "LoggedOut",
}

export abstract class BaseSessionContext {

  private _log: ILogger;

  settingUp: boolean;
  fbUser: User|null = null; // not null when authenticated
  fbUserWatch = new Watchable<User|null>();

  private _state: SessionContextState = SessionContextState.UserIsAuthenticating;

  public stateSubject = new BehaviorSubject<SessionContextState>(SessionContextState.UserIsAuthenticating);

  public sessionIsReady = false;

  get state() {

    return this._state;
  }

  public async buildAuthenticatedProxy( httpClient: HttpClient ): Promise<AuthenticatedProxy> {

    if( !this.fbUser ) {

      return null;
    }

    const idToken = await this.fbUser.getIdToken();

    const answer = new AuthenticatedProxy( httpClient, idToken );
    return answer;
  }


  public abstract signInWithEmailAndPassword(email: string, password: string ): Promise<IFirebaseError|null>;
  public abstract signInWithEmailLink(email: string, emailLink: string ): Promise<IFirebaseError|null>;
  public abstract signInWithPhone( confirmationResult: ConfirmationResult, phoneAuthCode: string ): Promise<IFirebaseError|null>;

  public abstract sendSignInLinkToEmail( email: string ): Promise<IFirebaseError|null>;

  set state( value: SessionContextState ) {

    this._log.debug( `${this._state} => ${value}`);
    this.stateSubject.next( value );
    this._state = value;

    if( SessionContextState.UserIsReady === value ) {

      this.sessionIsReady = true;
    } else {

      this.sessionIsReady = false;
    }

  }

  async signOut() {

    AppStorage.clear();

    this.state = SessionContextState.LoggedOut;
    return this.afAuth.signOut();
  }


  constructor( log: ILogger, public afAuth: AngularFireAuth ) {


    this._log = log;
  }

}
