import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {CodeHelper} from "../../javascript.lib.mojo-base/util/CodeHelper";


export class BankingEnumeratedConstants {


  public static YES = EnumeratedConstantReference.yes;
  public static NO = EnumeratedConstantReference.no;
  public static NOT_APPLICABLE = EnumeratedConstantReference.notApplicable;



  public static ACCESS_TO_SIGN_LANGUAGE_INTERPRETER: {

    none: IEnumeratedConstant,
    faceToFace: IEnumeratedConstant,
    remote: IEnumeratedConstant
  } = {

    none: {
      codeAsNumber: 0x6e6f6e65,
      codeAsString: 'none',
      label: 'No',
      popupLabel: 'No Interpreting Service',
    },
    faceToFace: {
      codeAsNumber: 0x663266,
      codeAsString: 'f2f',
      label: 'Face to Face Interpreting',
      popupLabel: 'Face to Face Interpreting',
    },
    remote: {
      codeAsNumber: 0x726d6f74,
      codeAsString: 'rmot',
      label: 'Remote Interpreting Service',
      popupLabel: 'Remote Interpreting Service',
    },
  };

  public static ACCESS_TO_LANGUAGE_TRANSLATION_SERVICES: {

    none: IEnumeratedConstant,
    faceToFace: IEnumeratedConstant,
    remote: IEnumeratedConstant
  } = {

    none: {
      codeAsNumber: 0x6e6f6e65,
      codeAsString: 'none',
      label: 'No',
      popupLabel: 'No Translation Service',
    },
    faceToFace: {
      codeAsNumber: 0x663266,
      codeAsString: 'f2f',
      label: 'Face to Face Service',
      popupLabel: 'Face to Face Service',
    },
    remote: {
      codeAsNumber: 0x726d6f74,
      codeAsString: 'rmot',
      label: 'Remote Service',
      popupLabel: 'Remote Service',
    },
  };


  public static ACCESSIBILITY_CERTIFICATION: {

    ada: IEnumeratedConstant,
    iso: IEnumeratedConstant,
    dac: IEnumeratedConstant,
    other: IEnumeratedConstant,
    none: IEnumeratedConstant,
  } = {

    // , , ,
    ada: {
      codeAsNumber: 0x616461,
      codeAsString: 'ada',
      label: 'ADA Cert',
      popupLabel: 'ADA Certificate',
    },
    iso: {
      codeAsNumber: 0x69736f,
      codeAsString: 'iso',
      label: 'ISO Cert',
      popupLabel: 'ISO Certificate',
    },
    dac: {
      codeAsNumber: 0x646163,
      codeAsString: 'dac',
      label: 'Disability Access Cert',
      popupLabel: 'Disability Access Certificate',
    },
    other: {
      codeAsNumber: 0x6f746872,
      codeAsString: 'othr',
      label: 'Other Certificate',
      popupLabel: 'Other Accessibility Certification',
    },
    none: {
      codeAsNumber: 0x6e6f6e65,
      codeAsString: 'none',
      label: 'None',
      popupLabel: 'No Accessibility Certification',
    },
  };

  public static ALTERNATE_ENTRANCE_DOOR_TYPE: {

    automatic: IEnumeratedConstant,
    pushPad: IEnumeratedConstant,
    manual: IEnumeratedConstant,
    doorBell: IEnumeratedConstant,
  } = {

    automatic: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Fully automatic sensor',
      popupLabel: 'Fully automatic door at alternate entrance',
    },
    pushPad: {
      codeAsNumber: 0x70706164,
      codeAsString: 'ppad',
      label: 'Push pad activated',
      popupLabel: 'Push pad activated door at alternate entrance',
    },
    manual: {
      codeAsNumber: 0x6d616e75,
      codeAsString: 'manu',
      label: 'Manual',
      popupLabel: 'Manual door at alternate entrance',
    },
    doorBell: {
      codeAsNumber: 0x62656c6c,
      codeAsString: 'bell',
      label: 'Doorbell/Intercom',
      popupLabel: 'Doorbell/Intercom at alternate entrance',
    },
  };


  public static BATHROOM_DOOR_LOCK_TYPE: {
    leverHandle: IEnumeratedConstant,
    slideBolt: IEnumeratedConstant,
    thumbTurn: IEnumeratedConstant,
    other: IEnumeratedConstant,
  } = {
    leverHandle: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever handle lock',
      popupLabel: 'Lever handle door lock',
    },
    slideBolt: {
      codeAsNumber: 0x73626c74,
      codeAsString: 'sblt',
      label: 'Latch slide bolt lock',
      popupLabel: 'Latch slide bolt door lock',
    },
    thumbTurn: {
      codeAsNumber: 0x7474726e,
      codeAsString: 'ttrn',
      label: 'Thumb Turn lock',
      popupLabel: 'Thumb Turn door lock',
    },
    other: {
      codeAsNumber: 0x6f746872,
      codeAsString: 'othr',
      label: 'Other',
      popupLabel: 'Other door lock',
    },
  };


  public static CAFETERIA_TYPE: {
    selfService: IEnumeratedConstant,
    buffetService: IEnumeratedConstant,
    counterService: IEnumeratedConstant,
    tableService: IEnumeratedConstant,
  } = {

    selfService: {
      codeAsNumber: 0x73656c66,
      codeAsString: 'self',
      label: 'Self-service',
      popupLabel: 'Self-service Cafeteria',
    },
    buffetService: {
      codeAsNumber: 0x62756666,
      codeAsString: 'buff',
      label: 'Buffet service',
      popupLabel: 'Buffet service Cafeteria',
    },
    counterService: {
      codeAsNumber: 0x636e7472,
      codeAsString: 'cntr',
      label: 'Counter service',
      popupLabel: 'Counter service Cafeteria',
    },
    tableService: {
      codeAsNumber: 0x7461626c,
      codeAsString: 'tabl',
      label: 'Table service',
      popupLabel: 'Table service Cafeteria',
    },
  };


  // Does the building have a car park? ... (row: 3)
  public static CAR_PARK_FACILITIES: {
    no: IEnumeratedConstant,
    outdoor: IEnumeratedConstant,
    indoor: IEnumeratedConstant,
    both: IEnumeratedConstant,
    street: IEnumeratedConstant,
  } = {

    no: {
      codeAsNumber: 0x6e6f,
      codeAsString: 'no',
      label: 'No Car Park',
      popupLabel: 'No Car Park',
    },
    outdoor: {
      codeAsNumber: 0x6f757464,
      codeAsString: 'outd',
      label: 'Outdoor Car Park',
      popupLabel: 'Outdoor Car Park',
    },
    indoor: {
      codeAsNumber: 0x696e64,
      codeAsString: 'ind',
      label: 'Indoor Car Park',
      popupLabel: 'Indoor Car Park',
    },
    both: {
      codeAsNumber: 0x626f7468,
      codeAsString: 'both',
      label: 'Both Indoor and Outdoor Car Park',
      popupLabel: 'Both Indoor and Outdoor Car Park',
    },
    street: {
      codeAsNumber: 0x73747274,
      codeAsString: 'strt',
      label: 'On-Street Parking',
      popupLabel: 'On-street designated accessible parking spaces close by',
    },
  };



  public static DOOR_PUSH_PAD_LOCATION: {
    pole: IEnumeratedConstant,
    wall: IEnumeratedConstant,
    door: IEnumeratedConstant,
    side: IEnumeratedConstant,

  } = {

    pole: {
      codeAsNumber: 0x706f6c65,
      codeAsString: 'pole',
      label: 'On a pole',
      popupLabel: 'Push-pad on a pole',
    },
    wall: {
      codeAsNumber: 0x77616c6c,
      codeAsString: 'wall',
      label: 'by the wall',
      popupLabel: 'Push-pad by the wall',
    },
    side: {
      codeAsNumber: 0x73696465,
      codeAsString: 'side',
      label: 'at the side of the door',
      popupLabel: 'Push-pad at the side of the door',
    },
    door: {
      codeAsNumber: 0x646f6f72,
      codeAsString: 'door',
      label: 'on the door',
      popupLabel: 'Push-pad on the door',
    },
  };

  public static DOOR_HANDLE_TYPE: {

    lever: IEnumeratedConstant,
    vertical: IEnumeratedConstant,
    round: IEnumeratedConstant,
    push: IEnumeratedConstant,
  } = {
    lever: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever handle',
      popupLabel: 'Lever handle on door',
    },
    vertical: {
      codeAsNumber: 0x76657274,
      codeAsString: 'vert',
      label: 'Vertical door handle',
      popupLabel: 'Vertical door handles',
    },
    round: {
      codeAsNumber: 0x726f756e,
      codeAsString: 'roun',
      label: 'Round handle',
      popupLabel: 'Round handle on door',
    },
    push: {
      codeAsNumber: 0x70757368,
      codeAsString: 'push',
      label: 'Push door without handles',
      popupLabel: 'Push door without handles',
    },
  };


  public static ENTRANCE_DOOR_TYPE: {

    automatic: IEnumeratedConstant,
    pushPad: IEnumeratedConstant,
    manual: IEnumeratedConstant,
    revolving: IEnumeratedConstant,
  } = {

    automatic: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Automatic self-opening',
      popupLabel: 'Automatic door',
    },
    pushPad: {
      codeAsNumber: 0x70706164,
      codeAsString: 'ppad',
      label: 'Push pad/button activated',
      popupLabel: 'Push pad door',
    },
    manual: {
      codeAsNumber: 0x6d616e75,
      codeAsString: 'manu',
      label: 'Manual',
      popupLabel: 'Manual door',
    },
    revolving: {
      codeAsNumber: 0x7265766f,
      codeAsString: 'revo',
      label: 'Revolving',
      popupLabel: 'Revolving door',
    },
  };


  // 'BkzJn' / 'CmhaZ'
  public static FURNITURE_TYPE: {
    movable: IEnumeratedConstant,
    fixed: IEnumeratedConstant,
    both: IEnumeratedConstant,
    notApplicable: IEnumeratedConstant,
  } = {
    movable: {
      codeAsNumber: 0x6d6f7661,
      codeAsString: 'mova',
      label: 'Movable',
      popupLabel: 'Movable furniture',
    },
    fixed: {
      codeAsNumber: 0x66697864,
      codeAsString: 'fixd',
      label: 'Fixed',
      popupLabel: 'Fixed furniture',
    },
    both: {
      codeAsNumber: 0x626f7468,
      codeAsString: 'both',
      label: 'Movable and fixed',
      popupLabel: 'Movable and fixed furniture',
    },
    notApplicable: EnumeratedConstantReference.notApplicable
  };

  public static INNER_DOOR_TYPE: {

    automatic: IEnumeratedConstant,
    pushPad: IEnumeratedConstant,
    manual: IEnumeratedConstant,
  } = {

    automatic: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Automatic self opening inner door',
      popupLabel: 'Automatic inner door',
    },
    pushPad: {
      codeAsNumber: 0x70706164,
      codeAsString: 'ppad',
      label: 'Push pad activated inner door',
      popupLabel: 'Push pad inner door',
    },
    manual: {
      codeAsNumber: 0x6d616e75,
      codeAsString: 'manu',
      label: 'Manual inner door',
      popupLabel: 'Manual',
    },
  };

  public static CARD_MACHINE_KEYPAD_LAYOUT: {

    telephone: IEnumeratedConstant,
    calculator: IEnumeratedConstant
  } = {

    telephone: {
      codeAsNumber: 0x70686f6e,
      codeAsString: 'phon',
      label: 'Telephone layout',
      popupLabel: 'Telephone layout on card machines',
    },
    calculator: {
      codeAsNumber: 0x63616c63,
      codeAsString: 'calc',
      label: 'Calculator layout',
      popupLabel: 'Calculator layout on card machines',
    },
  };




  public static STEP_FREE_ACCESS_TYPE: {
    level: IEnumeratedConstant,
    ramp: IEnumeratedConstant,
    lift: IEnumeratedConstant,
  } = {
    level: {
      codeAsNumber: 0x6c65766c,
      codeAsString: 'levl',
      label: 'Level',
      popupLabel: 'Level entrance',
    },
    ramp: {
      codeAsNumber: 0x72616d70,
      codeAsString: 'ramp',
      label: 'Ramp',
      popupLabel: 'Ramp entrance',
    },
    lift: {
      codeAsNumber: 0x6c696674,
      codeAsString: 'lift',
      label: 'Lift',
      popupLabel: 'Lift entrance',
    },
  }

  // What type of door handles are on the accessible bathroom doors?  (row: 31)
  public static TOILET_DOOR_TYPE: {

    automaticDoor: IEnumeratedConstant,
    pushButtonOpener: IEnumeratedConstant,
    leverHandles: IEnumeratedConstant,
    roundHandles: IEnumeratedConstant,
    push: IEnumeratedConstant,
  } = {

    automaticDoor: {
      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Automatic door',
      popupLabel: 'Automatic toilet door',
    },
    pushButtonOpener: {
      codeAsNumber: 0x6275746e,
      codeAsString: 'butn',
      label: 'Push Button/Pad opener',
      popupLabel: 'Push Button/Pad toilet door opener',
    },
    leverHandles: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever handles',
      popupLabel: 'Lever handles on toilet door',
    },
    roundHandles: {
      codeAsNumber: 0x726f756e,
      codeAsString: 'roun',
      label: 'Round handles',
      popupLabel: 'Round handles on toilet door',
    },
    push : {
      codeAsNumber: 0x70757368,
      codeAsString: 'push',
      label: 'Push door without handles',
      popupLabel: 'Push door without handles on toilet door',
    },
  };

  //  What type of handle is on the sink? (row: 38)
  public static TOILET_SINK_HANDLE_TYPE: {

    lever: IEnumeratedConstant,
    round: IEnumeratedConstant,
    press: IEnumeratedConstant,
    sensor: IEnumeratedConstant,
  } = {

    lever: {
      codeAsNumber: 0x6c657672,
      codeAsString: 'levr',
      label: 'Lever tap handles',
      popupLabel: 'Lever tap handles on toilet sink',
    },
    round: {
      codeAsNumber: 0x726f756e,
      codeAsString: 'roun',
      label: 'Swirl/Round head tap handles',
      popupLabel: 'Swirl/Round head tap handles on toilet sink',
    },
    press: {
      codeAsNumber: 0x70726573,
      codeAsString: 'pres',
      label: 'Press taps',
      popupLabel: 'Press taps on toilet sink',
    },
    sensor: {
      codeAsNumber: 0x736e7372,
      codeAsString: 'snsr',
      label: 'Sensor/Infrared operated',
      popupLabel: 'Sensor/Infrared operated on toilet sink',
    },
  };

  public static TOUCH_SCREEN_REACHABLE: {

    yes: IEnumeratedConstant,
    no: IEnumeratedConstant,
    some: IEnumeratedConstant
  } = {
    yes: {
      codeAsNumber: 0x1,
      codeAsString: '1',
      label: 'Yes (all machines)',
      popupLabel: 'All machines reachable',
    },
    no: EnumeratedConstantReference.no,
    some: {
      codeAsNumber: 0x736f6d65,
      codeAsString: 'some',
      label: 'Some Machines',
      popupLabel: 'Some Machines reachable',
    }
  };


  public static printCodes( enumeratedType: string, codes: IEnumeratedConstant[] ) {

    console.log( `${enumeratedType} ...`);

    for( const code of codes ) {

      const numberCode = CodeHelper.asciiToNumberCode( code.codeAsString );
      const hexNumberCode = numberCode.toString(16);

      if( numberCode != code.codeAsNumber ) {

        console.log( `ERROR: numberCode != code.codeAsNumber; code.codeAsString: '${code.codeAsString}'; hexNumberCode: 0x${hexNumberCode}\`` );

      } else {

        console.log( `'${code.codeAsString}' -> 0x${hexNumberCode}`);
      }
    }

  }


  public printCodes() {


    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.ACCESS_TO_SIGN_LANGUAGE_INTERPRETER', -1, BankingEnumeratedConstants.ACCESS_TO_SIGN_LANGUAGE_INTERPRETER);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.ACCESS_TO_LANGUAGE_TRANSLATION_SERVICES', -1, BankingEnumeratedConstants.ACCESS_TO_LANGUAGE_TRANSLATION_SERVICES);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.ACCESSIBILITY_CERTIFICATION', -1, BankingEnumeratedConstants.ACCESSIBILITY_CERTIFICATION);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE', -1, BankingEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE', -1, BankingEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE );
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.CAFETERIA_TYPE', -1, BankingEnumeratedConstants.CAFETERIA_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.CAR_PARK_FACILITIES', -1, BankingEnumeratedConstants.CAR_PARK_FACILITIES);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.DOOR_HANDLE_TYPE', -1, BankingEnumeratedConstants.DOOR_HANDLE_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.DOOR_PUSH_PAD_LOCATION', -1, BankingEnumeratedConstants.DOOR_PUSH_PAD_LOCATION);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.ENTRANCE_DOOR_TYPE', -1, BankingEnumeratedConstants.ENTRANCE_DOOR_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.FURNITURE_TYPE', -1, BankingEnumeratedConstants.FURNITURE_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.INNER_DOOR_TYPE', -1, BankingEnumeratedConstants.INNER_DOOR_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.PHONE_KEYPAD_LAYOUT', -1, BankingEnumeratedConstants.CARD_MACHINE_KEYPAD_LAYOUT);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.STEP_FREE_ACCESS_TYPE', -1, BankingEnumeratedConstants.STEP_FREE_ACCESS_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.TOILET_DOOR_TYPE', -1, BankingEnumeratedConstants.TOILET_DOOR_TYPE );
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.TOILET_SINK_HANDLE_TYPE', -1, BankingEnumeratedConstants.TOILET_SINK_HANDLE_TYPE);
    EnumeratedConstantReference.printEnum('BankingEnumeratedConstants.TOUCH_SCREEN_REACHABLE', -1, BankingEnumeratedConstants.TOUCH_SCREEN_REACHABLE);
  }



  private _toTypescriptEnumValues( enums: { [codeAsString: string]: IEnumeratedConstant; }) {

    let answer = "";

    for( const key of Object.keys(enums) ) {

      const enumValue: IEnumeratedConstant = enums[key];

      const hexCode = enumValue.codeAsNumber.toString(16);
      const line = `${key} = 0x${hexCode}, // '${enumValue.codeAsString}'\n`

      answer += line;

    }

    return answer;

  }

  addEnum( enumValues: any, enums: { [codeAsString: string]: IEnumeratedConstant; } ) {

    for( const key of Object.keys(enumValues) ) {

      const enumValue: IEnumeratedConstant =  enumValues[key];
      enums[key] = enumValue;
    }
  }


  generateTypescriptEnumValues() {

    const enums: { [codeAsString: string]: IEnumeratedConstant; } = {};

    this.addEnum( BankingEnumeratedConstants.ACCESSIBILITY_CERTIFICATION, enums );
    this.addEnum( BankingEnumeratedConstants.ACCESS_TO_LANGUAGE_TRANSLATION_SERVICES, enums );
    this.addEnum( BankingEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.CAFETERIA_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.CAR_PARK_FACILITIES, enums );
    this.addEnum( BankingEnumeratedConstants.DOOR_PUSH_PAD_LOCATION, enums );
    this.addEnum( BankingEnumeratedConstants.DOOR_HANDLE_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.ENTRANCE_DOOR_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.FURNITURE_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.INNER_DOOR_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.CARD_MACHINE_KEYPAD_LAYOUT, enums );
    this.addEnum( BankingEnumeratedConstants.STEP_FREE_ACCESS_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.TOILET_DOOR_TYPE, enums );
    this.addEnum( BankingEnumeratedConstants.TOILET_SINK_HANDLE_TYPE, enums );

    const typescriptValues = this._toTypescriptEnumValues( enums );
    console.log( `# typescriptValues vvv\n\n${typescriptValues}\n\n` );
  }


}
