import {IDependantDescriptor, QuestionReference} from '../Question';
import {QuestionKey} from "../QuestionKey";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";


/**
 * @deprecated use `ISectionQuestions2`
 */
export interface IPageQuestions {

  headerText?: string;
  questionKeys: QuestionKey[];
  dependant?: IDependantDescriptor;
}

/**
 * @deprecated use `SectionQuestions2`
 */
export class PageQuestions {

  private _log: ILogger = LoggerFactory.build( 'PageQuestions' );

  questions: QuestionReference[] = [];
  questionsByKey: {[key: string]: QuestionReference} = {};

  constructor( public value: IPageQuestions,
               questionsByKey: {[key: string]: QuestionReference} ) {

    for( const questionKey of value.questionKeys ) {
      const q = questionsByKey[ questionKey ];
      if( !q ) {

        if( 'Bk3dP' === questionKey ) {

          this._log.error( '!q', 'questionKey', questionKey, 'questionsByKey', questionsByKey );
        } else {

          // this._log.error( '!q', 'questionKey', questionKey );
        }
        continue;
      }
      this.questions.push( q );
      this.questionsByKey[ q.value.key ] = q;
    }
  }

}


